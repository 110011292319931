<script setup>
import RocIcon from "@/components/ui/RocIcon.vue";
import Confirmation from "./Confirmation.vue";
import { ref, computed, watchEffect, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

const COUNTDOWN_DEFAULT = 60;
const store = useStore();
const router = useRouter();
const showDialog = ref(false);
const timer = ref(COUNTDOWN_DEFAULT);
let interval = undefined;
let sessionInterval = undefined;
const loggedOutUser = ref(false);

const isLoggedIn = computed(() => {
  return store.getters["auth/isAuthenticated"];
});

const sessionTimer = computed(() => {
  return store.getters["settings/getInactiveTimer"] - COUNTDOWN_DEFAULT;
});

watchEffect(() => {
  if (isLoggedIn.value && sessionTimer.value > 0) {
    window.addEventListener("mousemove", startSessionLogic);
    window.addEventListener("keypress", startSessionLogic);
  } else {
    window.removeEventListener("mousemove", startSessionLogic);
    window.removeEventListener("keypress", startSessionLogic);
    resetIntervals();
  }
});

function startSessionInterval() {
  if (sessionInterval) {
    return;
  }

  sessionInterval = setInterval(() => {
    startTimer();
    showDialog.value = true;
  }, sessionTimer.value * 1000);
}

function startTimer() {
  if (interval) {
    return;
  }
  interval = setInterval(() => {
    if (timer.value === 0) {
      handleLogout();
    } else {
      timer.value--;
    }
  }, 1000);
}

function startSessionLogic() {
  if (showDialog.value) return;

  clearInterval(sessionInterval);
  sessionInterval = undefined;
  startSessionInterval();
}

async function handleReAuth() {
  resetIntervals();
  showDialog.value = false;
  timer.value = COUNTDOWN_DEFAULT;
}

function resetIntervals() {
  clearInterval(interval);
  interval = undefined;
  clearInterval(sessionInterval);
  sessionInterval = undefined;
}

function handleLogout(userInitLogout) {
  userInitLogout ? (showDialog.value = false) : (loggedOutUser.value = true);
  resetIntervals();
  timer.value = COUNTDOWN_DEFAULT;
  router.push({ path: "/logout" });
}

function handleSessionExpAck() {
  loggedOutUser.value = false;
  timer.value = COUNTDOWN_DEFAULT;
  showDialog.value = false;
}
</script>

<template>
  <base-dialog :show="true" :hideCloseBtn="true" v-if="showDialog">
    <Confirmation
      @yes="handleReAuth"
      @no="handleLogout(true)"
      :yesButtonTitle="'Continue Watching'"
      :noButtonTitle="'Logout'"
      v-if="!loggedOutUser"
    >
      <template #title>
        <RocIcon
          color="red"
          size="md"
          icon="unknown"
          style="margin-right: var(--spacing-m);"
        />
        Are you still Watching?
      </template>
      <div class="d-flex flex-row" style="margin-top:var(--spacing-xl)">
        <div
          class="overwatch-body-large"
          style="width:300px; flex-wrap: wrap; margin-bottom: var(--spacing-s)"
        >
          Your Session is about to expire. You will be logged out in
          <span style="font-weight: bold;"> {{ timer }}</span>
        </div>
      </div>
    </Confirmation>
    <Confirmation
      @yes="handleSessionExpAck"
      :yesButtonTitle="'Ok'"
      :showNoButton="false"
      v-else
    >
      <template #title>
        <RocIcon
          color="red"
          size="md"
          icon="error"
          style="margin-right: var(--spacing-m);"
        />
        Your session has expired.
      </template>
      <div class="d-flex flex-row" style="margin-top:var(--spacing-xl)">
        <div
          class="overwatch-body-large"
          style="width:300px; flex-wrap: wrap; margin-bottom: var(--spacing-s)"
        >
          Your session has timed out. Please log in again to continue Watching.
        </div>
      </div>
    </Confirmation>
  </base-dialog>
</template>

<style></style>

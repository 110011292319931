<template>
  <div>
    <search-header
      :showBack="true"
      button-title="Download"
      keepButtonTitle
      @button-clicked="downloadVideo()"
      :hide-search="true"
    >
      <template v-slot:leftnav>
        <div class="d-flex justify-content-between">
          <div class="align-self-center" style="float: left;">
            <RocIcon
              style="cursor: pointer; margin-right: 24px; transform: rotate(90deg)"
              icon="downArrow"
              size="sm"
              @click="$router.back()"
            />
          </div>
          <div class="d-flex justify-content-start flex-wrap" style="">
            <div class="camera-name" style="width: 100%;">
              {{ encounterObj.cameraName }}
            </div>
            <div class="clip-date" style="margin-right: 8px;">
              {{ formattedEncounterDetailsDate(startMS) }}
            </div>
            <div class="clip-time-container">
              <span class="clip-time">{{
                formattedEncounterTime(startMS)
              }}</span>
              <span
                class="clip-date"
                style="margin-right: 6px; margin-left: 6px;"
                >-</span
              >
              <span class="clip-time">{{
                formattedEncounterTime(stopMS)
              }}</span>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:rightnav>
        <div style="height: 100%; border: 0px solid black; width: 200px;">
          <div
            class="clip-nav-text"
            style="float: left; margin-right: 20px; cursor: pointer;"
            @click="loadClip('previous')"
          >
            Previous Clip
          </div>
          <div
            class="clip-nav-text"
            style="float: left; margin-top: auto; margin-bottom: auto; cursor: pointer;"
            @click="loadClip('next')"
          >
            Next Clip
          </div>
        </div>
      </template>
    </search-header>

    <div
      ref="videoContainerDiv"
      class="d-flex justify-content-center flex-wrap video-container-div"
    >
      <video
        :class="videoClass"
        controls
        preload="auto"
        ref="videoplayer"
        :src="videoBytes"
        @timeupdate="updateTime($event.target.currentTime)"
        @ended="ended()"
        playsinline
      >
        Sorry, your browser doesn't support embedded videos.
      </video>
    </div>
    <div class="d-flex justify-content-center player-text">
      {{ playbackTime }}
    </div>

    <div class="d-flex justify-content-start flex-wrap detections-picker-div">
      <DetectionsPicker
        :camera-id="encounterObj.video.cameraGUID"
        :start="startMS"
        :stop="stopMS"
        @timestamp-update="updateByAbsoluteTime"
      ></DetectionsPicker>
    </div>
    <!--<pre>{{ encounterObj }}</pre>-->
  </div>
</template>

<script>
import { onMounted, onUnmounted, ref } from "vue";
import SearchHeader from "@/components/ui/SearchHeader";
import { useStore } from "vuex";
import { get as lodashGet } from "lodash";
import DetectionsPicker from "@/components/encounters/DetectionsPicker";
import RocIcon from "@/components/ui/RocIcon";

export default {
  components: {
    DetectionsPicker,
    SearchHeader,
    RocIcon
  },
  props: {
    encounter: String
  },
  setup(props) {
    const store = useStore();
    const videoplayer = ref(null);
    const currentTime = ref(0);
    const encounterObj = JSON.parse(atob(props.encounter));
    const restEndpointPrefix = "/rest/v1/media/video/";
    const src = ref(
      restEndpointPrefix + lodashGet(encounterObj, "video.mediaId", "")
    );
    const videoBytes = ref(null);
    const startMS = ref(encounterObj.video.startMS);
    const stopMS = ref(encounterObj.video.stopMS);
    const videoContainerDiv = ref(null);
    const videoClass = ref("fade-in");
    const mediaTimeDelta = ref(0);
    const playbackTime = ref(0);

    currentTime.value = Math.abs(
      (encounterObj.timestamp - startMS.value) / 1000
    );
    if (!currentTime.value) {
      currentTime.value = 0;
    }
    console.log("Playback start time: ", currentTime.value);

    function ended() {
      console.log("ended / stopped");
    }

    function logtime() {
      console.log("currentTime: ", currentTime.value);
    }

    onMounted(async () => {
      await loadVideoAuth(src.value);
      videoplayer.value.isPlaying = true;
      videoplayer.value.currentTime = currentTime.value;
      videoplayer.value.requestVideoFrameCallback((now, metadata) => {
        getAccurateFrameTime(now, metadata);
        processFrame(now, metadata);
      });
      window.addEventListener("resize", handleResize);
    });

    function getAccurateFrameTime(now, metadata) {
      playbackTime.value = formattedEncounterTime(
        metadata.mediaTime.toFixed(3) * 1000 + startMS.value
      ); // + (mediaTimeDelta.value * 1000);
      //console.log("playback value: ", playbackTime.value);
      videoplayer.value.requestVideoFrameCallback(getAccurateFrameTime);
      /*console.log("fired: ", metadata);
      console.log("dynamic delta: ", (currentTime.value - metadata.mediaTime));
      console.log("now: ", now);*/
    }

    function processFrame(now, metadata) {
      //console.log(now, metadata);
      mediaTimeDelta.value = currentTime.value - metadata.mediaTime; //seems kind of crazy, but there's a precision issue on currentTime, metadata.mediaTime appears to be exact.
      videoplayer.value.currentTime += mediaTimeDelta.value; //since we can only control video playback offset with currenTime, mediaTimeDelta is the inaccuracy delta
      //console.log("mediaTimeDelta: ", mediaTimeDelta.value);       //added to fix the alignment issues
      //videoplayer.value.requestVideoFrameCallback(processFrame);
      //videoplayer.value.requestVideoFrameCallback(updateCanvas)    //note, doesn't appear to be implemented in latest version of firefox.
    }

    function handleResize() {
      videoContainerDiv.value.style.height = "auto";
    }

    onUnmounted(() => {
      URL.revokeObjectURL(videoBytes.value);
    });

    function updateTime(time) {
      currentTime.value = time;
    }

    function play() {
      videoplayer.value.play();
    }

    function pause() {
      videoplayer.value.pause();
    }

    function updateByAbsoluteTime(time) {
      //console.log("event time: ", time);
      const newOffset = Math.abs((time - startMS.value) / 1000);
      currentTime.value = newOffset;
      videoplayer.value.currentTime = currentTime.value; // + mediaTimeDelta.value;
      videoplayer.value.requestVideoFrameCallback(processFrame);
      //console.log("offset: ", newOffset);
      //playbackTime.value = formattedEncounterTime(time);
    }

    function formattedEncounterDetailsDate(epochTime) {
      //console.log("epoch_time: ", epochTime);
      const eventDate = new Date(epochTime);
      let formattedDate =
        dayOfWeekAsString(eventDate.getDay()) +
        " " +
        ("0" + Number(eventDate.getMonth() + 1)).slice(-2) +
        "-" +
        ("0" + eventDate.getDate()).slice(-2) +
        "-" +
        eventDate.getFullYear();
      //formattedDate += ` ${formattedEncounterTime(epochTime)}`;
      return formattedDate;
    }

    function formattedEncounterTime(time) {
      const eventDate = new Date(time);
      return (
        eventDate.toLocaleTimeString([], { hour12: true }).split(" ")[0] +
        "." +
        ("0" + eventDate.getMilliseconds()).slice(-3) +
        " " +
        (eventDate.getHours() >= 12 ? "PM" : "AM")
      ); // .toString() + ':' + ('0' + eventDate.getMilliseconds()).slice(-3) + ' ' + (eventDate.getHours() >= 12) ? 'PM' : 'AM'; //+ ':' + eventDate.getMilliseconds();
    }

    function dayOfWeekAsString(dayIndex) {
      return (
        ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"][dayIndex] || ""
      );
    }

    function downloadVideo() {
      const mediaId = lodashGet(encounterObj, "video.mediaId", "");
      const cameraName = lodashGet(encounterObj, "cameraName", "cameraName");
      if (mediaId) {
        const startTime = new Date(startMS.value);
        let formattedTimeStr =
          startTime.toLocaleTimeString([], { hour12: false }) +
          "." +
          ("0" + startTime.getMilliseconds()).slice(-3); // .toString() + ':' + ('0' + eventDate.getMilliseconds()).slice(-3) + ' ' + (eventDate.getHours() >= 12) ? 'PM' : 'AM'; //+ ':' + eventDate.getMilliseconds();
        formattedTimeStr = formattedTimeStr.replace(/:/g, "");
        const formattedDateTimeStr =
          startTime.getFullYear() +
          ("0" + Number(startTime.getMonth() + 1)).slice(-2) +
          ("0" + startTime.getDate()).slice(-2) +
          "_" +
          formattedTimeStr;
        const file = `${cameraName}_${formattedDateTimeStr}_${mediaId}.mp4`; //${encounterObj.cameraName}_${encounterObj}_
        const a = document.createElement("a");
        a.href = videoBytes.value;
        a.download = file;
        document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
        a.click();
        a.remove(); //afterwards we remove the element again
      }
    }

    async function loadVideoAuth(src) {
      const httpPayload = {
        url: src,
        payload: { method: "GET" }
      };

      const base64Image = await store.dispatch(
        "auth/fetchBinaryResourceAsBase64",
        httpPayload
      );
      videoBytes.value = base64Image;
    }

    async function loadClip(direction) {
      videoClass.value = "fade-out";
      const nextVideoInfo = await loadNavigateVideoInfo(
        encounterObj.video.cameraGUID,
        encounterObj.video._id,
        direction
      );
      if (nextVideoInfo) {
        videoContainerDiv.value.style.height = `${videoContainerDiv.value.offsetHeight}px`;
        src.value = restEndpointPrefix + nextVideoInfo.mediaId;
        await loadVideoAuth(src.value);
        encounterObj.video = nextVideoInfo;
        startMS.value = nextVideoInfo.startMS;
        stopMS.value = nextVideoInfo.stopMS;
        videoClass.value = "fade-in";
      }
    }

    async function loadNavigateVideoInfo(cameraId, cursorId, direction) {
      const url = "/rest/v1/media/video/navigate";
      const postBody = {
        cameraId: cameraId,
        cursorVideoObjectId: cursorId,
        direction: direction
      };
      const httpPayload = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(postBody)
      };

      try {
        const responseData = await store.dispatch(
          "auth/fetchJSON",
          { url: url, payload: httpPayload },
          { root: true }
        );
        console.log(responseData);
        if (responseData.status === "success") {
          return responseData.video;
        }
      } catch (error) {
        error.value = error.message || "Something went wrong!";
      }
    }

    return {
      videoplayer,
      updateTime,
      play,
      pause,
      currentTime,
      logtime,
      ended,
      src,
      encounterObj,
      formattedEncounterDetailsDate,
      formattedEncounterTime,
      downloadVideo,
      videoBytes,
      updateByAbsoluteTime,
      loadClip,
      startMS,
      stopMS,
      videoContainerDiv,
      videoClass,
      mediaTimeDelta,
      playbackTime
    };
  }
};
</script>

<style scoped lang="scss">
.outer-container {
  border: 1px dotted black;
  text-align: left;
  margin-top: 20px;
}
.inner-container {
  border: 1px solid black;
  display: inline-block;
  position: relative;
}
.video-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 10px;
  padding: 5px 5px;
  @include overwatch-body-large;
  color: #ffffff;
  background-color: rgba(50, 50, 50, 0.3);
}
video {
  width: 100%;
  max-width: 1440px;
  height: auto;
}

.camera-name {
  color: var(--overwatch-neutral-100);
  @include overwatch-title-med;
  line-height: 35px;
}

.clip-date {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-med;
  line-height: 30px;
}

.clip-time {
  color: var(--overwatch-neutral-100);
  @include overwatch-title-large;
  line-height: 30px;
}

.clip-nav-text {
  color: var(--overwatch-primary);
  @include overwatch-title-small;
  line-height: 25px;
}

.player-text {
  color: var(--overwatch-neutral-100);
  @include overwatch-title-med;
}

.fade-in {
  opacity: 1;
  animation-name: fadeInOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.2s;
}

.fade-out {
  opacity: 0;
  animation-name: fadeOutOpacity;
  animation-iteration-count: 1;
  animation-timing-function: ease-out;
  animation-duration: 0.2s;
}

@keyframes fadeInOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOutOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

<style scoped>
.video-container-div {
  height: auto;
  min-width: 900px;
  background-color: black;
  margin: 20px 20px 0px 20px;
}

.player-text {
  min-width: 900px;
  height: 30px;
  background-color: black;
  margin: 0px 20px 20px 20px;
}

.detections-picker-div {
  min-width: 900px;
  height: auto;
  margin: 20px 20px 20px 20px;
}

.clip-time-container {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 480px) {
  video {
    all: revert;
    height: 100%;
    width: 100%;
  }
  .video-container-div {
    all: revert;
    height: auto;
  }

  .player-text {
    all: revert;
    height: 30px;
    background-color: black;
    color: white;
  }

  .detections-picker-div {
    all: revert;
    height: auto;
    margin-top: 20px;
  }

  .clip-time-container {
    display: flex;
    flex-wrap: nowrap;
  }

  .clip-date {
    font-size: 16px;
  }
  .clip-time {
    font-size: 16px;
  }
}
</style>

<template>
  <div>
    <div class="left-right">
      <nav>
        <div>
          <span v-if="editingCase" class="overwatch-title-small">
            {{ editingCase.name }}
          </span>
          <span
            class="overwatch-body-med"
            style="margin-left: var(--spacing-s); color: var(--overwatch-neutral-300)"
          >
            {{ editingCase ? currentStep - 1 : currentStep }}/{{
              editingCase ? 3 : 4
            }}
          </span>
        </div>
        <ol>
          <li v-if="!editingCase" :class="{ active: currentStep === 1 }">
            Case Information
          </li>
          <li :class="{ active: currentStep === 2 }">Select Files</li>
          <li :class="{ active: currentStep === 3 }">Parse Files</li>
          <li :class="{ active: currentStep === 4 }">Configure Cameras</li>
          <li :class="{ active: currentStep === 5 }">Upload</li>
        </ol>
      </nav>

      <div class="component">
        <!-- Main component area -->
        <CaseDetails
          v-if="currentStep === 1"
          @change-name="(v) => (caseName = v)"
          @change-description="(v) => (caseDescription = v)"
          @change-watchlists="(v) => (selectedWatchlists = v)"
          @change-usergroups="(v) => (selectedUserGroups = v)"
        />

        <SelectFiles
          v-else-if="currentStep === 2"
          @change-analytics="(v) => (selectedAnalytics = v)"
          @change-files="(v) => (files = v)"
          @change-timezone="(v) => (defaultTimezone = v)"
        />

        <ParseFileDetails
          v-else-if="currentStep === 3"
          ref="parseFileDetailsRef"
          :files="files"
          :defaultTimezone="defaultTimezone"
          @change-cameranames="(v) => (filenamesToCameraname = v)"
          @change-datetimes="(v) => (filenamesToDatetime = v)"
        />

        <!-- Step 3 -->
        <div
          v-else-if="currentStep === 4"
          style="height: 100%; display: flex; flex-direction: column;"
        >
          <!-- Camera step  -->
          <div class="d-flex cameras">
            <div style="height: 100%; width: fit-content; overflow: auto;">
              <MDBListGroup light class="camera-list-group">
                <div class="overwatch-body-med" style="margin-bottom: 1px;">
                  Detected Cameras
                </div>
                <MDBListGroupItem
                  v-for="camera in tempCameraObjects"
                  tag="button"
                  noBorder
                  spacing
                  action
                  :active="selectedCamera === camera"
                  @click="selectedCamera = camera"
                >
                  {{ camera.name }}
                </MDBListGroupItem>
              </MDBListGroup>
              <MDBListGroup
                light
                class="camera-list-group"
                v-if="editingCaseCameras.length > 0"
                style="margin-top: 16px;"
              >
                <div class="overwatch-body-med" style="margin-bottom: 1px;">
                  Previously Processed Cameras
                </div>
                <MDBListGroupItem
                  v-for="camera in editingCaseCameras"
                  tag="button"
                  noBorder
                  spacing
                  action
                  :active="selectedCamera === camera"
                  @click="selectedCamera = camera"
                >
                  {{ camera.name }}
                </MDBListGroupItem>
              </MDBListGroup>
            </div>

            <div class="camera-crud-box">
              <div>Settings for {{ selectedCamera.name }}</div>
              <CameraSettings
                :key="settingsKey"
                :disabled="'vs_config' in selectedCamera"
                style="width: 100%"
                :vs-config="selectedCamera.vs_config"
                :analytics="selectedCamera.analytics"
                :enableThreshold="
                  selectedCamera.matchThresholdOverride?.enabled
                "
                :threshold="selectedCamera.matchThresholdOverride?.threshold"
                :hideSettings="['enabled', 'record']"
                @change="handleChangedCameraSettings"
                @start="handleStartCameraSettings"
              />
            </div>
          </div>
        </div>

        <!-- Step 4 - Progress-->
        <div
          v-else-if="currentStep === 5"
          style="height: 100%; display: flex; flex-direction: column;"
        >
          <div style="font-size: 10px;">
            Total Progress
          </div>
          <MDBProgress :height="20">
            <MDBProgressBar :value="caseProgress">
              {{ caseProgress }}%
            </MDBProgressBar>
          </MDBProgress>
          <div style="margin-top: 10px; height: 100%; overflow-y: auto;">
            <div class="panel-header">
              <div
                class="d-flex justify-content-between align-self-center"
                style="width:100%"
              >
                <div
                  class="flex-child"
                  style="display: inline-block; text-align: left; min-width: 60%;"
                >
                  Filename
                </div>
                <div
                  class="flex-child"
                  style="display: inline-block; text-align: left; min-width: 20%"
                >
                  Status
                </div>
                <div
                  class="flex-child"
                  style="display: inline-block; text-align: left; min-width: 20%"
                ></div>
              </div>
            </div>
            <div class="panel" v-for="m in caseMediaStatuses">
              <div
                style="min-width: 60%; text-overflow: ellipsis; overflow: hidden;"
                :title="m.fileName"
              >
                {{ m.fileName }}
              </div>
              <div style="min-width: 35%">
                <div v-if="m.status === 'processing'">
                  {{
                    liveProgressForFilename[m.fileName]
                      ? liveProgressForFilename[m.fileName].toFixed(1)
                      : 0
                  }}%
                </div>
                <div v-else>
                  {{
                    m.statusDescription
                      ? m.status + " " + "-" + " " + m.statusDescription
                      : m.status
                  }}
                </div>
              </div>
              <div
                style="min-width: 5%; display: flex; flex-direction: row-reverse;"
              >
                <roc-spinner size="sm" v-if="m.status === 'uploading'" />
                <roc-spinner size="sm" v-if="m.status === 'processing'" />
                <RocIcon
                  icon="check"
                  size="sm"
                  v-else-if="m.status === 'completed'"
                  color="primary"
                />
                <RocIcon
                  icon="error"
                  size="sm"
                  v-else-if="m.status === 'error'"
                  color="red"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      <div class="footer-btns">
        <div class="footer-message">{{ footerMessage }}</div>
        <roc-spinner style="margin-right: 10px;" v-show="isLoading" />
        <RocButton
          style="padding: var(--spacing-s)"
          size="md"
          :disabled="isNextDisabled"
          @click="handleNextClick"
        >
          <div
            style="display:flex; align-items: center; gap: var(--spacing-xs)"
          >
            {{ nextButtonText }}
            <RocIcon
              v-if="nextButtonText === 'Next'"
              size="xxs"
              icon="downArrow"
              color="white"
              rotate270
            />
          </div>
        </RocButton>
      </div>
    </footer>
  </div>
  <BaseDialog
    v-if="isShowingCloseDialog"
    :show="true"
    title="Close Wizard?"
    @close="isShowingCloseDialog = false"
    style="z-index:9999"
    noPadding
  >
    <DeleteConfirmation
      @close="isShowingCloseDialog = false"
      @delete="closeWizard"
      message="Close Wizard"
    >
      Are you sure you want to close?
    </DeleteConfirmation>
  </BaseDialog>
  <BaseDialog
    :show="showUpdateConfirmation"
    @close="showUpdateConfirmation = false"
    :hideCloseBtn="true"
    :style="updateConfirmationStyle"
  >
    <div style="width: 100%;" class="d-flex flex-row justify-content-center">
      <div style="display: flex; align-items: center; justify-content: center;">
        <span
          style="display: flex; align-items: center; justify-content: center;"
          class="Check-Circle-Icon"
          ><img src="@/assets/img/icons/icons_black_32x32_match.svg"
        /></span>
      </div>
      <div
        style="color:var(--overwatch-neutral-100)"
        class="overwatch-title-small"
      >
        Stop will occur after in progress medias are complete
      </div>
    </div>
  </BaseDialog>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import {
  MDBListGroup,
  MDBListGroupItem,
  MDBProgress,
  MDBProgressBar
} from "mdb-vue-ui-kit";

import { get as lodashGet } from "lodash";

/** Steps */
import CaseDetails from "@/components/cases/wizard/CaseDetails.vue";
import SelectFiles from "@/components/cases/wizard/SelectFiles.vue";
import ParseFileDetails from "@/components/cases/wizard/ParseFileDetails.vue";

import CameraSettings from "@/components/cameras/CameraSettings.vue";
import DeleteConfirmation from "@/components/settings/DeleteConfirmation";
import BaseDialog from "@/components/ui/BaseDialog";
import RocButton from "@/components/ui/RocButton.vue";
import RocIcon from "@/components/ui/RocIcon.vue";

const store = useStore();

const props = defineProps(["close"]);
const emit = defineEmits(["close"]);

const isImporterVisible = computed(() => {
  return store.getters["cases/importerVisible"];
});

// Edit mode, prepopulate
const editingCase = computed(() => {
  return store.getters["cases/editingCase"];
});

// Returns object of case being imported.
// If undefined, import was interrupted
const importerCaseId = computed(() => {
  return store.getters["cases/cases"].find((c) => c._id === caseId.value);
});

watch(isImporterVisible, async () => {
  if (
    isImporterVisible.value ||
    store.getters["watchlists/watchlists"].length === 0
  ) {
    await store.dispatch("watchlists/loadWatchlists");
  }
});

const currentStep = ref(1);

const caseName = ref("");
const caseDescription = ref("");
const selectedWatchlists = ref([]);
const selectedUserGroups = ref([]);

/* Analytics */
const selectedAnalytics = ref([]);
const availableAnalytics = ref({
  Face: "face",
  Pedestrian: "pedestrian",
  Gun: "gun",
  "License Plate": "lpr",
  Vehicle: "vehicle",
  "OCR In-The-Wild": "ocr",
  Tattoo: "tattoo"
});
const files = ref([]);
const filenamesToCameraname = ref();
const filenamesToDatetime = ref();

const parseFileDetailsRef = ref();

const defaultTimezone = ref();

const editingCaseCameras = ref([]);

watch([editingCase, isImporterVisible], async () => {
  if (editingCase.value && isImporterVisible.value) {
    resetWizard();

    currentStep.value = 2;

    let c = editingCase.value;
    caseId.value = c._id;

    const camResponse = await store.dispatch("cases/getCamerasByCaseId", {
      caseId: c._id
    });
    editingCaseCameras.value = camResponse.result;
  }
});

const caseProgress = ref(0);

/* misc */

const isNextDisabled = ref(false);
const isLoading = ref(false);
const footerMessage = ref("");

async function handleNextClick() {
  var isValid = false;
  isNextDisabled.value = true;
  isLoading.value = true;

  switch (currentStep.value) {
    case 1:
      if (!caseName.value.length > 0) {
        footerMessage.value = "Please enter a name.";
      }

      isValid = caseName.value.length > 0;
      break;
    case 2:
      if (!selectedAnalytics.value.length > 0) {
        footerMessage.value = "Please select analytics.";
        break;
      }
      if (files.value.length === 0) {
        footerMessage.value = "Please select files.";
        break;
      }
      isValid = true;
      break;

    case 3:
      parseFileDetailsRef.value.emitDetails(); // Populates cameraname and datetime objects

      if (
        Object.values(filenamesToCameraname.value).filter(
          (e) => e && e.length > 0
        ).length !== files.value.length
      ) {
        footerMessage.value = "Please set all camera names.";
        break;
      }
      if (
        Object.keys(filenamesToDatetime.value).length !== files.value.length
      ) {
        footerMessage.value = "Please set all date times.";
        break;
      }
      isValid = processFiles();
      break;

    case 4:
      var response;

      if (!editingCase.value) {
        response = await createCase();
      } else {
        // Skipping first step during Add File, so just "validate response"
        response = "success";
      }

      if (validateCreationResponses(response)) {
        response = await createCameras();
      }

      if (validateCreationResponses(response)) {
        footerMessage.value = "";
        isValid = true;
      } else {
        isValid = false;
        footerMessage.value =
          "Unexpected error when uploading media or creating cases.";
      }
      break;
    case 5:
      if (isProcessingCase.value) {
        await stopProcessingCase();
        isProcessingCase.value = false;
      } else {
        resetWizard();
        emit("close");
        // Finish and exit.
      }
      break;
  }

  if (isValid) {
    footerMessage.value = "";
    currentStep.value++;
  }
  isLoading.value = false;
  isNextDisabled.value = false;
  isValid = false;
}

const caseId = ref("");
async function createCase() {
  const payload = {
    name: caseName.value,
    description: caseDescription.value,
    watchlistIds: selectedWatchlists.value,
    userGroups: selectedUserGroups.value,
    timezone: defaultTimezone.value,
  };
  var response = await store.dispatch("cases/createCase", payload);

  if (response.status === "success") {
    caseId.value = response.result._id;
  }
  return response.status;
}

const tempCameraObjects = ref([]);
const selectedCamera = ref();
const tempMediaObjects = ref([]);

watch(
  tempCameraObjects,
  (newVal) => {
    if (!selectedCamera.value) {
      selectedCamera.value = tempCameraObjects.value[0];
    }
  },
  { deep: true }
);

// A small hack to force reload the CameraSettings child component when a camera is selected.
// Doesn't reload for some reason without this.
const settingsKey = ref(0);
watch(selectedCamera, (newVal) => {
  settingsKey.value++;
});

function processFiles() {
  const seenNames = new Set();

  for (let f of files.value) {
    const split = f.fileHandle.name.split(".");
    const noext = split.slice(0, split.length - 1).join(".");

    let cameraName = filenamesToCameraname.value[noext];
    let startDatetime = filenamesToDatetime.value[noext];

    if (!seenNames.has(cameraName)) {
      tempCameraObjects.value.push({
        name: cameraName,
        analytics: selectedAnalytics.value
      });
      seenNames.add(cameraName);
    }

    tempMediaObjects.value.push({
      cameraName: cameraName, // Temporary to associate object to a camera
      fileName: f.fileHandle.name,
      file: f.fileHandle,
      startTime: startDatetime
    });
  }
  return true;
}

function handleChangedCameraSettings(newSettings) {
  const idx = tempCameraObjects.value.findIndex(
    (x) => x.name === selectedCamera.value.name
  );
  if (idx > -1) {
    for (let setting in newSettings) {
      tempCameraObjects.value[idx][setting] = newSettings[setting];
    }
  }
}

// CameraSettings emits settings to all objects upon start.
const started = ref(false);
function handleStartCameraSettings(newSettings) {
  if (!started.value) {
    for (let i = 0; i < tempCameraObjects.value.length; i++) {
      for (let setting in newSettings) {
        tempCameraObjects.value[i][setting] = newSettings[setting];
      }
    }
  }
  started.value = true;
}

const cameraNameIdMap = {};
async function createCameras() {
  const responses = [];
  for (let tempCameraObject of tempCameraObjects.value) {
    let payload = await createCameraPayload(tempCameraObject);
    var response;
    if (editingCaseCameras.value.map((m) => m.name).includes(payload.name)) {
      response = await store.dispatch("cases/updateCamera", payload);
    } else {
      response = await store.dispatch("cases/createCamera", payload);
    }
    responses.push(response.status);
    cameraNameIdMap[response.result.name] = response.result._id;
  }
  return responses;
}

watch(currentStep, async () => {
  // Execute when current step reaches 4
  if (currentStep.value === 5) {
    await uploadMedia();
    await startProcessingCase();
  }
});

const caseMediaStatuses = ref([]);
async function uploadMedia() {
  isNextDisabled.value = true;
  isProcessingCase.value = true;
  // Set statuses to loading
  // {fileName: , status: }
  for (let ob of tempMediaObjects.value) {
    caseMediaStatuses.value.push({
      fileName: ob.fileName,
      status: "uploading"
    });
  }

  for (let i = 0; i < tempMediaObjects.value.length; i++) {
    let tempMediaObject = tempMediaObjects.value[i];
    tempMediaObject.cameraId = cameraNameIdMap[tempMediaObject.cameraName];
    tempMediaObject.caseId = caseId.value;

    delete tempMediaObject.cameraName;

    var response = await store.dispatch("cases/uploadMedia", tempMediaObject);
    if (response.status === "success") {
      caseMediaStatuses.value[i].status = "uploaded";
    } else {
      caseMediaStatuses.value[i].status = "upload failed";
    }
  }
}

function validateCreationResponses(res) {
  if (typeof res === "string") {
    return res === "success";
  } else {
    return res.every((r) => r === "success");
  }
}

async function createCameraPayload(tempCameraObject) {
  let newDoc = {};
  let vsConfig;
  newDoc.name = tempCameraObject.name;
  newDoc.enabled = tempCameraObject.enabled;

  for (let analytic of tempCameraObject.analytics) {
    if (!vsConfig) {
      const result = await store.dispatch(
        "cameras/getVSConfigDefaultByModality",
        availableAnalytics.value[analytic]
      );
      if (result.status === "success") {
        vsConfig = result.value;
      }
    } else {
      const result = await store.dispatch(
        "cameras/getVSConfigDefaultByModality",
        availableAnalytics.value[analytic]
      );
      // get just the analytics backend of this result.
      const analyticsBackend =
        result.value.roc.tracker["analytics-backends"][0];

      vsConfig.roc.tracker["analytics-backends"].push(analyticsBackend);
    }
  }

  newDoc.vs_config = vsConfig;

  newDoc.matchThresholdOverride = {
    enabled: tempCameraObject.matchThresholdOverride.enabled,
    threshold: tempCameraObject.matchThresholdOverride.threshold
  };

  newDoc.caseId = caseId.value;

  return newDoc;
}

const nextButtonText = computed(() => {
  switch (currentStep.value) {
    case 4:
      return "Start Import";
      break;
    case 5:
      if (isProcessingCase.value) {
        return "Stop Processing";
      } else {
        return "Finish";
      }
      break;
    default:
      return "Next";
  }
});

const isProcessingCase = ref(false);
const sockets = ref([]);
const liveProgressForFilename = ref({});

async function startProcessingCase() {
  isProcessingCase.value = true;

  // Add a 'processing' tag for CaseManagement screen reactivity
  const payload = {
    id: caseId.value,
    ...store.getters["cases/cases"].find((c) => c._id === caseId.value),
    processing: true
  };
  store.commit("cases/replaceCase", payload);

  const result = await store.dispatch("cases/startProcessingCase", {
    caseId: caseId.value
  });

  var caseUpdateInterval = setInterval(async () => {
    //Evan - note: if a case is suddenly deleted, this will throw an error in the console
    //it's due to how often this gets called, and the case is deleted before the next call
    var response = await getCaseStatus();

    if (response && isNextDisabled.value) {
      // It looks better when the Next button is still disabled after media upload
      // until we can visually confirm that the case is processing.
      isNextDisabled.value = false;
    }

    caseMediaStatuses.value = lodashGet(response, "result", []);
    var finishedCount = 0;

    caseMediaStatuses.value.forEach((m) => {
      if (m.status === "completed" || m.status === "error") {
        finishedCount++;
      }
    });

    if (
      caseMediaStatuses.value &&
      finishedCount === caseMediaStatuses.value.length
    ) {
      caseProgress.value = 100;
      clearInterval(caseUpdateInterval);
      isProcessingCase.value = false;
      disconnectAndResetSockets();

      //may want to add this back in if there are issues with videos sticking around for next upload
      //caseMediaStatuses.value = [];
    }
  }, 1000);

  // livestats
  const camResponse = await store.dispatch("cases/getCamerasByCaseId", {
    caseId: caseId.value
  });
  const cameras = camResponse.result;

  if (cameras.length > 0) {
    for (let camera of cameras) {
      let socket = await store.dispatch(
        "auth/getSocketIO",
        `feed=livestats&topic=${camera.GUID}`
      );

      socket.on(camera.GUID, (payload) => {
        const cameraStats = payload.cameraStats;
        const parts = cameraStats.filename.split("/");
        const filename = parts[parts.length - 1];
        const progress = cameraStats.progress;

        liveProgressForFilename.value[filename] = progress;
      });

      sockets.value.push(socket);
    }
  }
}

watch(
  liveProgressForFilename,
  (nv) => {
    // Skip if liveProgress object is reset
    if (Object.keys(nv).length === 0) {
      return;
    }

    const alreadyFinishedMedia = caseMediaStatuses.value.filter(
      (s) => s.status === "completed" || s.status === "error"
    );
    const numberOfFiles = caseMediaStatuses.value.length;

    var totalPercentage = alreadyFinishedMedia.length * 100;

    for (var [key, value] of Object.entries(nv)) {
      if (alreadyFinishedMedia.map((m) => m.fileName).includes(key)) continue;
      totalPercentage += Number(value);
    }

    caseProgress.value = (totalPercentage / (numberOfFiles * 100)) * 100;
    caseProgress.value = Number(caseProgress.value.toFixed(1));
  },
  { deep: true }
);

async function getCaseStatus() {
  if (importerCaseId.value && importerCaseId.value.id && caseId.value) {
    return await store.dispatch("cases/getCaseStatus", {
      caseId: importerCaseId.value.id
    });
  }
}

const windowWidth = ref(window.innerWidth);
onMounted(() => {
  window.addEventListener("resize", () => {
    windowWidth.value = window.innerWidth;
  });
});

const updateConfirmationStyle = computed(() => {
  if (windowWidth.value <= 480) {
    // Mobile style
    return {
      width: "90%"
    };
  }
});

const showUpdateConfirmation = ref(false);
async function stopProcessingCase() {
  let result = await store.dispatch("cases/stopProcessingCase", {
    caseId: caseId.value
  });
  closeWizard();
  showUpdateConfirmation.value = true;
  setTimeout(() => {
    showUpdateConfirmation.value = false;
  }, 5000);
}

/**
 * Reset wizard when it's closed / finished.
 */
function resetWizard() {
  currentStep.value = 1;
  caseName.value = "";
  caseDescription.value = "";
  selectedWatchlists.value = [];
  selectedAnalytics.value = [];
  selectedUserGroups.value = [];
  caseProgress.value = 0;

  isNextDisabled.value = false;
  isLoading.value = false;
  footerMessage.value = "";

  caseId.value = "";
  files.value = [];
  filenamesToCameraname.value = null;
  filenamesToDatetime.value = null;
  tempCameraObjects.value = [];
  selectedCamera.value = null;
  tempMediaObjects.value = [];

  started.value = false;

  caseMediaStatuses.value = [];
  isProcessingCase.value = false;

  liveProgressForFilename.value = {};
  caseProgress.value = 0;

  disconnectAndResetSockets();
}

const isShowingCloseDialog = ref(false);

watch(
  () => props.close,
  () => {
    isShowingCloseDialog.value = true;
  }
);

function disconnectAndResetSockets() {
  sockets.value.forEach((s) => {
    s.disconnect();
    s.removeAllListeners();
  });
  sockets.value = [];
}

function closeWizard() {
  resetWizard();
  emit("close");
}
</script>

<style lang="scss">
input {
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;
}

.camera-list-group {
  @include overwatch-body-med;
  width: 200px;
  border-radius: 0;
  flex: 1;
}

.camera-list-group button {
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid var(--overwatch-neutral-300) !important;
  border-radius: 5px;
  background-color: var(--overwatch-neutral-200);
  color: var(--overwatch-button-text);
}

.camera-list-group button:last-child {
  border-radius: 5px;
}

//Custom arrow (until refresh)
.occurrence {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  background-image: linear-gradient(
      45deg,
      transparent 50%,
      var(--overwatch-neutral-400) 50%
    ),
    linear-gradient(135deg, var(--overwatch-neutral-400) 50%, transparent 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.occurrence:focus {
  background-image: linear-gradient(
      45deg,
      var(--overwatch-neutral-400) 50%,
      transparent 50%
    ),
    linear-gradient(135deg, transparent 50%, var(--overwatch-neutral-400) 50%),
    linear-gradient(to right, transparent, transparent);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

.camera-list-group button.active {
  color: var(--overwatch-button-text);
  background-color: var(--overwatch-primary) !important;
  border: 1px solid var(--overwatch-neutral-300) !important;
  border-radius: 5px;
}

.multiselect-tag {
  @include overwatch-body-small;
  color: var(--overwatch-button-primary);
  border: solid 1px var(--overwatch-button-primary);
}

.multiselect-dropdown {
  /* Magic that fixes blurry dropdown text on Chrome, on a 1440p monitor */
  border-radius: 4px;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-secondary);
  border: none;
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.25));
}

.multiselect .multiselect-no-results {
  color: var(--overwatch-neutral-200) !important;
}
</style>

<style scoped lang="scss">
nav {
  display: flex;
  flex-direction: column;

  padding: 20px 10px 0 10px;
  gap: 10px;

  @include overwatch-body-small;
  border-right: 1px solid var(--overwatch-neutral-300);
}
ol {
  padding-left: var(--spacing-m);
  margin: 0 var(--spacing-s);
}

li {
  margin-bottom: var(--spacing-s);
  color: var(--overwatch-neutral-300) !important;
}

li.active {
  color: var(--overwatch-neutral-100) !important;
  cursor: default;
}

.left-right {
  display: flex;
}

.component {
  margin: 10px 10px 10px 10px;
  width: 700px;
  height: 600px;
  @include overwatch-body-large;
}

.input-row {
  width: 100%;
  margin-bottom: var(--spacing-base);
}

.analytics-row {
  display: flex;
  gap: 30px;

  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 12px;
  margin-top: 4px;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 4px;
  @include overwatch-body-med;
}

.inline {
  width: revert;
  display: inline;
}

input:disabled {
  background-color: var(--overwatch-neutral-300);
}

textarea {
  margin-top: 4px;
  width: 100%;
  padding: 5px;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 4px;
  resize: none;
  padding: 12px;
  @include overwatch-body-med;
}

div {
  user-select: none;
}

footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  margin: 0 10px 10px 10px;
}

.footer-btns {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.cameras {
  flex: 1;

  display: flex;
  height: 100%;
}

.camera-crud-box {
  margin-left: 10px;
  padding-left: 10px;
  border-left: 1px dashed var(--overwatch-neutral-100);
  flex: 2;
}

.panel-header {
  width: 100%;
  height: 24px;
}

.panel {
  width: 100%;
  height: 3em;
  margin-bottom: 2px;
  padding: 4px !important;
  border: 1px solid var(--overwatch-neutral-300);
  border-radius: 5px;

  display: flex;
  align-items: center;

  @include overwatch-body-med;
}

.footer-message {
  display: inline-block;
  color: var(--overwatch-error);
}

.black {
  color: black;
}

select {
  display: inline-block;
  border: 1px solid var(--overwatch-neutral-100);
  width: 100px;
}

.Check-Circle-Icon {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 12px;
  border: 1px solid #000000;
  display: inline-block;
  background-color: var(--overwatch-secondary);
}
</style>

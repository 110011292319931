const lodashMerge = require("lodash.merge");

export default {
  async loadAllCameras(context) {
    const responseData = await context.dispatch(
      "auth/fetchJSON",
      { url: "/rest/v1/cameras", payload: { method: "GET" } },
      { root: true }
    );
    const cameras = [];
    for (const key in responseData) {
      const camera = responseData[key];
      camera.value = camera.GUID;
      camera.label = camera.name;
      cameras.push(camera);
    }
    context.commit("setCameras", cameras);
  },
  async loadCamerasByActiveMission(context) {
    const mission = context.rootGetters["cases/activeMission"];
    const response = await context.dispatch(
      "cases/getCamerasByCaseId",
      { caseId: mission._id },
      { root: true }
    );
    if (response.status === "success") {
      const cameras = [];
      for (const key in response.result) {
        const camera = response.result[key];
        camera.value = camera.GUID;
        camera.label = camera.name;
        cameras.push(camera);
      }
      context.commit("setCameras", cameras);
    } else {
      context.commit("setCameras", []);
    }
  },
  async toggleCameraState(context, payload) {
    const toggleCamera = context.getters.findByGUID(payload.GUID);
    if (!toggleCamera) {
      throw new Error("camera not found");
    }

    const action = toggleCamera.enabled ? "stop" : "start";
    const url = `/rest/v1/camera/${action}/${payload.GUID}`;
    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: {}
    };

    const responseData = await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
    context.commit("setCameraState", responseData.camera);
    return responseData;
  },
  async testCameraConnection(context, payload) {
    const url = "/rest/v1/camera/testConnection";
    const httpPayload = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    };

    return await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
  },
  async updateCameraByUUID(context, payload) {
    if (payload.GUID) {
      const url = `/rest/v1/camera/${payload.GUID}`;
      const httpPayload = {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload.updates)
      };

      const responseData = await context.dispatch(
        "auth/fetchJSON",
        { url: url, payload: httpPayload },
        { root: true }
      );
      if (responseData.status === "success") {
        console.log("update camera response: ", responseData);
        context.commit("setCameraState", responseData.camera);
        return 0;
      } else {
        return responseData.message;
      }
    } else return null;
  },
  async addCamera(context, payload) {
    if (payload) {
      //const vsConfigForFace = await context.dispatch('getVSConfigDefaultByModality', 'face');
      //console.log('FaceVSConfig: ', vsConfigForFace);

      const url = "/rest/v1/camera/template";
      const cameraTemplateData = await context.dispatch(
        "auth/fetchJSON",
        {
          url: url,
          payload: {
            method: "GET"
          }
        },
        { root: true }
      );
      if (cameraTemplateData) {
        lodashMerge(cameraTemplateData, payload);
        const httpPayload = {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
          body: JSON.stringify(cameraTemplateData)
        };
        const responseData = await context.dispatch(
          "auth/fetchJSON",
          { url: `/rest/v1/camera`, payload: httpPayload },
          { root: true }
        );
        if (responseData.status === "success") {
          context.commit("addNewCamera", responseData.camera);
          return 0;
        } else {
          return responseData.message;
        }
      } else return null;
    }
  },
  async deleteCamera(context, payload) {
    if (payload) {
      const url = `/rest/v1/camera/${payload}`;
      const httpPayload = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      };

      const responseData = await context.dispatch(
        "auth/fetchJSON",
        { url: url, payload: httpPayload },
        { root: true }
      );

      console.log("delete response: ", responseData);

      if (responseData.status === "success") {
        context.commit("deleteCamera", payload);
      } else {
        return responseData.message;
      }
    }
  },
  async getVSConfigDefaultByModality(context, modality) {
    let url = `/rest/v1/objectstore/camera.${modality}.vs_config`;

    //since default config collection settings are stored in objectstores and its syntax differs from the other values
    if (modality === "read_only_config") {
      url = `/rest/v1/objectstore/read_only_default_config`;
    }

    const responseData = await context.dispatch(
      "auth/fetchJSON",
      {
        url: url,
        payload: { method: "GET" }
      },
      { root: true }
    );
    return responseData;
  },

  async updateObjectStoreConfig(context, payload) {
    let url = `/rest/v1/objectstore`;

    const httpPayload = {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    };

    // console.log("payload", JSON.stringify(httpPayload))
    const responseData = await context.dispatch(
      "auth/fetchJSON",
      { url: url, payload: httpPayload },
      { root: true }
    );
    return responseData;
  }
};

export default class dateHelper {
  constructor() {}

  /**
   * Returns Date object representing the provided string.
   * @param {String} dateString
   * @returns Date
   */
  getDateFromDateTimeString(dateTimeString) {
    if (!dateTimeString) {
      return new Date();
    } else {
      return new Date(dateTimeString);
    }
  }

  /**
   * Returns Date object representing the provided string.
   * If Date only is provided, resulting Date will be UTC.
   * If ISO Date String is provided (YYYY-MM-DDTHH:mm:ss.sssZ), will parse into current time zone.
   * - this is intended for situations when a date only was saved into Mongo, which saves time as 00:00 (UTC)
   * @param {String} dateString
   * @returns Date
   */ 1;
  getDateFromDateOnlyString(dateString) {
    if (!dateString) {
      return new Date();
    } else {
      return new Date(
        dateString.length >= 16 ? dateString.substring(0, 16) : dateString
      );
    }
  }

  /**
   * Returns a human readable string representing the date and time with day of week.
   * @param {Date} date
   * @returns String
   */
  getDisplayDateTime(date) {
    return `${this.dayOfWeekAsString(date.getDay())} ${date.getMonth() +
      1}-${date.getDate()}-${date.getFullYear()}
      at ${date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true
      })}`;
  }

  /**
   * Returns a human readable string representing the date with day of week.
   * @param {Date} date
   * @returns String
   */
  getDisplayDate(date) {
    return `${this.dayOfWeekAsString(date.getDay())} ${date.getMonth() +
      1}-${date.getDate()}-${date.getFullYear()}`;
  }

  /**
   * Takes the date, returns it in a fromat of MM-DD-YYYY
   * Ex. 03/21/1999
   * @param {Date} date
   * @returns String
   */
  formatYYYYMMDD(date) {
    return `${date.getMonth()}/${date.getDate()}/${date.getFullYear()}`;
  }

  dayOfWeekAsString(dayIndex) {
    return (
      [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday"
      ][dayIndex] || ""
    );
  }

  monthOfYearAsString(monthIndex) {
    return (
      [
        "January",
        "Febuary",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
      ][monthIndex] || ""
    );
  }

  formatDateMinutes() {
    const d = new Date();
    let hours = d.getHours();
    let minutes = d.getMinutes();
    let sec = d.getSeconds();
    const date = this.formatDate(d);

    if (hours.length < 2) hours = "0" + hours;
    if (minutes.length < 2) minutes = "0" + minutes;
    if (sec.length < 2) sec = "0" + sec;

    return `${date}${hours}${minutes}${sec}`;
  }

  formatDate(d) {
    let month = "" + (d.getMonth() + 1);
    let day = "" + d.getDate();
    let year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("");
  }

  /**
   * Takes Intl timeZone name and returns UTC offset int
   * ex. America/Los_Angeles : -07:00
   * @param {String} tz
   * @returns {String} offsetValue
   */
  getUTCOffsetFromTimezoneName(tz) {
    const offset = new Intl.DateTimeFormat("en", {
      timeZone: tz,
      timeZoneName: "shortOffset"
    })
      .formatToParts()
      .find((part) => part.type === "timeZoneName").value;
    const offsetValue = offset.replace("GMT", "");
    // -6, -6:30 etc.

    let [hour, minute] = offsetValue.split(":");

    let sign = "+";
    if (hour[0] === "-") {
      sign = "-";
      hour = hour.slice(1);
    }

    if (!minute) {
      minute = "00";
    }

    let offsetString = `${sign}${hour.padStart(2, 0)}:${minute}`;

    return offsetString;
  }
}

import { set as lodashSet } from "lodash";

export default {
  resetState(state, defaultState) {
    // Merge rather than replace so we don't lose observers
    Object.assign(state, defaultState);
  },
  setPlatform(state, value) {
    state.platform = value;
  },
  // cache the camera chosen for navigation between pages
  setSelectedCameraIndexFace(state, index) {
    state.userConfig.selectedCameraIndexFace = index;
  },
  setSelectedCameraIndexID(state, index) {
    state.userConfig.selectedCameraIndexID = index;
  },
  setSelectedCameraIndexBarcode(state, index) {
    state.userConfig.selectedCameraIndexBarcode = index;
  },
  saveFaceData(state, payload) {
    state.capturedFaceData = payload;
  },
  saveFaceMetrics(state, payload) {
    lodashSet(state, "capturedFaceData.faceMetrics", payload);
  },
  saveFaceTn(state, payload) {
    lodashSet(state, "capturedFaceData.faceThumbnail", payload);
  },
  saveFaceImage(state, payload) {
    lodashSet(state, "capturedFaceData.faceImage", payload);
  },
  saveFaceTnBackgroundRemoved(state, payload) {
    lodashSet(state, "capturedFaceData.faceThumbnailNoBackground", payload);
  },
  setCapturedFaceUploadFlag(state, flag) {
    state.capturedFaceUploadFlag = flag;
  },
  saveCredentialMetricsFaceDetect(state, metrics) {
    state.capturedIdentityDocData.identityDocFaceMetrics = metrics;
  },
  saveCredentialMetrics(state, metrics) {
    state.capturedIdentityDocData.identityDocMetrics = metrics;
    if (metrics && metrics.ocrStatus && metrics.ocrStatus.ocrFieldStatus) {
      state.identityData.docType = metrics.docName;
      for (let i = 0; i < metrics.ocrStatus.ocrFieldStatus.length; i++) {
        const tag = metrics.ocrStatus.ocrFieldStatus[i].tag;
        if (tag == "docnumber") {
          state.identityData.docNumber =
            metrics.ocrStatus.ocrFieldStatus[i].ocrResult.value;
        } else if (tag == "fname") {
          state.identityData.nameGiven =
            metrics.ocrStatus.ocrFieldStatus[i].ocrResult.value;
        } else if (tag == "lname") {
          state.identityData.nameSurname =
            metrics.ocrStatus.ocrFieldStatus[i].ocrResult.value;
        } else if (tag == "dob") {
          state.identityData.dob =
            metrics.ocrStatus.ocrFieldStatus[i].ocrResult.value;
        } else if (tag == "address") {
          state.identityData.addressStreet =
            metrics.ocrStatus.ocrFieldStatus[i].ocrResult.value;
        } else if (tag == "exp") {
          state.identityData.docExp =
            metrics.ocrStatus.ocrFieldStatus[i].ocrResult.value;
        } else if (tag == "personalnumber") {
          state.identityData.personalNumber =
            metrics.ocrStatus.ocrFieldStatus[i].ocrResult.value;
        }
      }
    }
  },
  setWebcamList(state, camList) {
    state.webcamList = camList;
  },
  setDevMode(state, mode) {
    state.userConfig.devMode = mode;
  },
  setIcaoFallback(state, enabled) {
    state.userConfig.icaoFallback = enabled;
  },
  saveIdentityDocPageOne(state, image) {
    state.capturedIdentityDocData.identityDocPageOne = image;
  },
  saveIdentityDocPageTwo(state, image) {
    state.capturedIdentityDocData.identityDocPageTwo = image;
  },
  saveIdentityDocFaceThumbnail(state, image) {
    state.capturedIdentityDocData.identityDocFaceThumbnail = image;
  },
  clearIdentityDocImageSegments(state) {
    state.capturedIdentityDocData.identityDocImageSegments = [];
  },
  addIdentityDocImageSegments(state, payload) {
    payload.forEach((element) => {
      state.capturedIdentityDocData.identityDocImageSegments.push({
        data: element.imageBase64,
        label: element.labelString
      });
    });
  },
  resetCapturedDataAll(state) {
    // captured face data
    state.capturedFaceData = {
      faceImage: null,
      faceThumbnail: null,
      faceMetrics: {}
    };
    // captured id data
    state.capturedIdentityDocData = {
      identityDocPageOne: null,
      identityDocPageTwo: null,
      identityDocFaceThumbnail: null,
      identityDocImageSegments: [],
      identityDocFaceMetrics: {},
      identityDocMetrics: {},
      identityDocBarcodeMrz: {},
      identityDocBarcodeMrzSkipped: false,
      identityDocType: null,
      identityDocPage1Timeout: false
    };
    // additional workflow fields
    state.scannedCacData = null;
    state.scannedDlData = null;
    state.identityData = {};
    state.vehicleData = {};
    state.rwwEnrolled = false;
    state.prevIdentityData = {};
    state.prevIdentityFound = false;
    // state stuff
    state.deviceSettings.frameCount = 0;
    state.deviceSettings.totalFrameTime = 0;
    state.deviceSettings.frameTime = 0;
    // additional docs
    state.rwwEnrollResult = null;
    state.sessionConfig.fingerprintMode = false;
    state.capturedFingerprintData = {
      data: null,
      position: null,
      format: "wsq",
      resolution: 500,
      metrics: {},
      tn: ""
    };
    state.rwwEnrollResult = null;
  },
  resetCapturedDataFace(state) {
    // face capture specific fields
    state.capturedFaceData = {
      faceImage: null,
      faceThumbnail: null,
      faceMetrics: {}
    };
    // fields that are affected by resetting face
    state.rwwEnrolled = false;
    state.prevIdentityData = {};
    state.prevIdentityFound = false;
  },
  resetCapturedDataID(state) {
    // ID capture specific fields
    state.capturedIdentityDocData = {
      identityDocPageOne: null,
      identityDocPageTwo: null,
      identityDocFaceThumbnail: null,
      identityDocImageSegments: [],
      identityDocFaceMetrics: {},
      identityDocMetrics: {},
      identityDocBarcodeMrz: {},
      identityDocBarcodeMrzSkipped: false,
      identityDocType: null,
      identityDocPage1Timeout: false
    };
    // fields that are affected by resetting ID
    state.rwwEnrolled = false;
    state.identityData = {};
  },
  setNameGiven(state, value) {
    state.identityData.nameGiven = value;
  },
  setNameSurname(state, value) {
    state.identityData.nameSurname = value;
  },
  setVehicleMake(state, value) {
    state.vehicleData.make = value;
  },
  setVehicleModel(state, value) {
    state.vehicleData.model = value;
  },
  setVehicleColor(state, value) {
    state.vehicleData.color = value;
  },
  addLicensePlate(state, value) {
    state.vehicleData.plates = [];
    state.vehicleData.plates.push(value);
  },
  setCompanyName(state, value) {
    state.identityData.companyName = value;
  },
  setConfirmationNumber(state, value) {
    state.identityData.confirmationNumber = value;
  },
  setAge(state, value) {
    state.identityData.age = value;
  },
  setNotes(state, value) {
    state.identityData.notes = value;
  },
  setCode(state, value) {
    state.identityData.code = value;
  },
  setAddressStreet(state, value) {
    state.identityData.addressStreet = value;
  },
  setDOB(state, value) {
    state.identityData.dob = value;
  },
  setCacData(state, cacData) {
    state.scannedCacData = cacData;
    if (state.scannedCacData) {
      state.identityData.nameGiven = state.scannedCacData.FirstName;
      state.identityData.nameSurname = state.scannedCacData.LastName;
      state.identityData.dob = state.scannedCacData.DateOfBirth;
    }
  },
  setDriversLicenseData(state, dlData) {
    state.scannedDlData = dlData;
    if (state.scannedDlData) {
      if (state.scannedDlData.DAC && state.scannedDlData.DAC.content) {
        state.identityData.nameGiven = state.scannedDlData.DAC.content;
      }
      if (state.scannedDlData.DCT && state.scannedDlData.DCT.content) {
        state.identityData.nameGiven = state.scannedDlData.DCT.content;
      }
      if (state.scannedDlData.DCS && state.scannedDlData.DCS.content) {
        state.identityData.nameSurname = state.scannedDlData.DCS.content;
      }
      if (state.scannedDlData.DAG && state.scannedDlData.DAG.content) {
        // street address
        state.identityData.addressStreet = state.scannedDlData.DAG.content;
      }
      if (
        state.scannedDlData.DBB &&
        state.scannedDlData.DBB.content &&
        state.scannedDlData.DBB.content.length === 8
      ) {
        // DOB
        //Parse scanned MMDDYYYY as YYYY-MM-DD
        const dobMMDDYYYY = state.scannedDlData.DBB.content;
        const dobParsed = new Date(
          parseInt(dobMMDDYYYY.substring(4), 10),
          parseInt(dobMMDDYYYY.substring(0, 2), 10) - 1,
          parseInt(dobMMDDYYYY.substring(2, 4), 10)
        );
        state.identityData.dob = dobParsed.toISOString().split("T")[0];
      }
    }
  },
  setRwwEnrolled(state, enrolled) {
    state.rwwEnrolled = enrolled;
  },
  setRwwSelectedWatchlist(state, wl) {
    state.userConfig.rwwSelectedWatchlist = {
      value: wl.value,
      label: wl.label,
      eventId: wl._eventId
    };
  },
  setUserSelectedCaptureConfig(state, value) {
    state.userConfig.userSelectedCaptureConfig = value;
    // if we're already initialized, this is change by the user, update the capture config state
    if (state.captureConfigs) {
      state.captureConfigs.setSelectedConfig(value);
    }
  },
  setSelectedCaptureConfig(state, value) {
    state.userConfig.selectedCaptureConfig = value;
    if (state.captureConfigs) {
      state.captureConfigs.setSelectedConfig(value);
    }
  },
  setFlagDeleteEnrollment(state, enabled) {
    state.userConfig.flagDeleteEnrollment = enabled;
  },
  updateDeviceFrameTime(state, value) {
    if (!state.deviceSettings.frameCount) {
      state.deviceSettings.frameCount = 0;
    }
    if (!state.deviceSettings.frameTime) {
      state.deviceSettings.frameTime = 0;
    }
    if (!state.deviceSettings.totalFrameTime) {
      state.deviceSettings.totalFrameTime = 0;
    }
    state.deviceSettings.frameCount++;
    state.deviceSettings.totalFrameTime += value;
    state.deviceSettings.frameTime = Math.round(
      state.deviceSettings.totalFrameTime / state.deviceSettings.frameCount
    );
  },
  setDocType(state, value) {
    state.identityData.docType = value;
  },
  setDocNumber(state, value) {
    state.identityData.docNumber = value;
  },
  setPersonalNumber(state, value) {
    state.identityData.personalNumber = value;
  },
  setDocExp(state, value) {
    state.identityData.docExp = value;
  },
  setCaptureConfigs(state, value) {
    state.captureConfigs = value;
    state.idAuthenticityEnforced = value.isIdAuthenticityEnforced();
    state.idOcrResultEnforced = value.isIdOcrResultEnforced();
  },
  setAppFlavorTag(state, value) {
    state.userConfig.appFlavorTag = value;
  },
  setAppFlavorConfig(state, flavorConfig) {
    state.sessionConfig.appFlavorConfig = flavorConfig;
  },
  setUserReady(state, value) {
    state.userConfig.userReadyFlag = value;
  },
  setPrevIdentityData(state, value) {
    state.prevIdentityData = value;
  },
  setPrevIdentityFound(state, value) {
    state.prevIdentityFound = value;
  },
  saveIdentityDocType(state, value) {
    state.capturedIdentityDocData.identityDocType = value;
  },
  saveDeviceSettingsResolution(state, value) {
    state.deviceSettings.resolution = value;
  },
  saveDeviceSettingsResolutionProvided(state, value) {
    state.deviceSettings.resolutionProvided = value;
    if (!state.captureMetrics.cameraInfo) {
      state.captureMetrics.cameraInfo = {};
    }
    state.captureMetrics.cameraInfo.resolution = value;
  },
  saveDeviceSettingsOrientation(state, value) {
    state.deviceSettings.naturalPortrait = value.naturalPortrait;
    state.deviceSettings.naturalLandscape = value.naturalLandscape;
    state.deviceSettings.isPortrait = value.isPortrait;
    state.deviceSettings.isLandscape = value.isLandscape;
  },
  setIcaoCaptureFallback(state, value) {
    state.userConfig.allowIcaoFallback = value;
  },

  saveCredentialBarcode(state, barcode) {
    state.capturedIdentityDocData.identityDocBarcodeMrz = barcode;
    if (barcode && barcode.barcodeStatus && barcode.barcodeStatus.fieldStatus) {
      for (let i = 0; i < barcode.barcodeStatus.fieldStatus.length; i++) {
        const tag = barcode.barcodeStatus.fieldStatus[i].tag;
        if (tag == "docnumber") {
          state.identityData.docNumber =
            barcode.barcodeStatus.fieldStatus[i].barcodeResult.value;
        } else if (tag == "fname") {
          state.identityData.nameGiven =
            barcode.barcodeStatus.fieldStatus[i].barcodeResult.value;
        } else if (tag == "lname") {
          state.identityData.nameSurname =
            barcode.barcodeStatus.fieldStatus[i].barcodeResult.value;
        } else if (tag == "dob") {
          state.identityData.dob =
            barcode.barcodeStatus.fieldStatus[i].barcodeResult.value;
        } else if (tag == "address") {
          state.identityData.addressStreet =
            barcode.barcodeStatus.fieldStatus[i].barcodeResult.value;
        } else if (tag == "exp") {
          state.identityData.docExp =
            barcode.barcodeStatus.fieldStatus[i].barcodeResult.value;
        } else if (tag == "personalnumber") {
          state.identityData.personalNumber =
            barcode.barcodeStatus.fieldStatus[i].barcodeResult.value;
        }
      }
    }
  },
  setCapturedIdentityDocBarcodeMrzSkipped(state, value) {
    state.capturedIdentityDocData.identityDocBarcodeMrzSkipped = value;
  },
  setCapturedIdentityDocPage1Timeout(state, value) {
    state.capturedIdentityDocData.identityDocPage1Timeout = value;
  },
  resetCaptureMetrics(state) {
    state.resetCaptureMetrics = {
      browserInfo: {},
      cameraInfo: {},
      faceFrameCount: 0,
      docTimedOut: false,
      docPage1FrameCount: 0,
      docPage2FrameCount: 0
    };
    state.startTime = 0;
  },
  setCaptureStartTime(state) {
    state.startTime = Date.now();
  },
  setCaptureMetricsBrowserInfo(state, value) {
    state.captureMetrics.browserInfo = value;
  },
  setCaptureMetricsCameraCapabilitiesID(state, value) {
    if (!state.captureMetrics.cameraInfo) {
      state.captureMetrics.cameraInfo = {};
    }
    state.captureMetrics.cameraInfo.capabilitiesID = value;
  },
  setCaptureMetricsCameraCapabilitiesFace(state, value) {
    if (!state.captureMetrics.cameraInfo) {
      state.captureMetrics.cameraInfo = {};
    }
    state.captureMetrics.cameraInfo.capabilitiesFace = value;
  },
  setCaptureMetricsCameraCapabilitiesUnknown(state, value) {
    if (!state.captureMetrics.cameraInfo) {
      state.captureMetrics.cameraInfo = {};
    }
    state.captureMetrics.cameraInfo.capabilitiesUnknown = value;
  },
  setCaptureMetricsCameraResolution(state, value) {
    if (!state.captureMetrics.cameraInfo) {
      state.captureMetrics.cameraInfo = {};
    }
    state.captureMetrics.cameraInfo.resolution = value;
  },
  setCaptureMetricsFaceFrameCount(state, value) {
    state.captureMetrics.faceFrameCount = value;
  },
  setCaptureMetricsDocTimedOut(state, value) {
    state.captureMetrics.docTimedOut = value;
  },
  setCaptureMetricsDocPage1FrameCount(state, value) {
    state.captureMetrics.docPage1FrameCount = value;
  },
  setCaptureMetricsDocPage2FrameCount(state, value) {
    state.captureMetrics.docPage2FrameCount = value;
  },
  setIsEntitlement(state, value) {
    state.isEntitlement = value;
  },
  setEntitlementData(state, value) {
    state.entitlementData = value;
  },
  setEnrollConfig(state, value) {
    state.enrollConfig = value;
  },
  setEventWorkflowEnabled(state, value) {
    state.eventWorkflowEnabled = value;
  },
  setVerifyOverride(state, value) {
    state.verifyOverride = value;
  },
  setCustomerId(state, value) {
    state.customerId = value;
  },
  setRwwEnrollResult(state, value) {
    state.rwwEnrollResult = value;
  },
  setFingerprintMode(state, value) {
    state.sessionConfig.fingerprintMode = value;
  },
  saveFingerprintData(state, payload) {
    state.capturedFingerprintData = payload;
  },
  setRegionIdCredential(state, value) {
    state.regionIdCredential = value;
  },
  setRegionIdBarcode(state, value) {
    state.regionIdBarcode = value;
  },
  setIsCitizen(state, value) {
    state.isCitizen = value;
  },
  setPaymentAmount(state, value) {
    state.paymentAmount = value;
  },
  setCustomerPin(state, value) {
    state.customerPin = value;
  },
  setReturnUrl(state, value) {
    state.returnUrl = value;
  },
  setBankInfoAccountNumber(state, value) {
    if (!state.identityData.bankInfo) {
      state.identityData.bankInfo = {};
    }
    state.identityData.bankInfo.AccountNumber = value;
  },
  setBankInfoBranchCode(state, value) {
    if (!state.identityData.bankInfo) {
      state.identityData.bankInfo = {};
    }
    state.identityData.bankInfo.BranchCode = value;
  },
  setBankInfoAccountType(state, value) {
    if (!state.identityData.bankInfo) {
      state.identityData.bankInfo = {};
    }
    state.identityData.bankInfo.AccountType = value;
  },
  setBankInfoIdCrNumber(state, value) {
    if (!state.identityData.bankInfo) {
      state.identityData.bankInfo = {};
    }
    state.identityData.bankInfo.IdCrNumber = value;
  },
  setBankInfoName(state, value) {
    if (!state.identityData.bankInfo) {
      state.identityData.bankInfo = {};
    }
    state.identityData.bankInfo.Name = value;
  },
  setAddressFull(state, value) {
    state.identityData.verifiedAddress = value;
  }
};

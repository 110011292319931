import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    retention: {},
    server_config: {},
    spoof_threshold: 0.25,
    deviceInfo: {},
    is_enroll_button_enabled: false,
    is_examine_button_enabled: false,
    is_watch_enabled: true,
    licenseFlags: {},
    appTerms: {},
    inactiveTimer: null,
    userAccessConfig: {},
    taggedFacesWatchlistConfig: {},
    faceTaggingEnabled: false,
    reverseSearchObjModalities: [],
    watchlistColors: [
      "#EEEFF4",
      "#F16884",
      "#F7A9B4",
      "#FAD287",
      "#FBE5A7",
      "#A1CF80",
      "#CDE1AF",
      "#62C9D7",
      "#B2E1E8",
      "#B27CB6",
      "#D5C3DF"
    ],
    watchlistDefaultColor: "#EEEFF4",
    encounterCardUX: {
      highlightMatchCandidate: false
    },
    userGroups: [],
    allVideoServiceIds: [],
    lowestLoadVideoServiceId: null,
    watchlistAdditionalInfoFields: [],
    darkMode: null, //starts null and then becomes a true/false value once a user config is made
    captureZonesInstructionsShown: false,
    isMissionsDisabled: false
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};

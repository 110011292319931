import { createStore } from "vuex";

import encountersModule from "./modules/encounters/index.js";
import requestsModule from "./modules/requests/index.js";
import camerasModule from "./modules/cameras/index.js";
import watchlistsModule from "./modules/watchlists/index.js";
import toolsModule from "./modules/tools/index";
import authModule from "./modules/auth/index";
import settingsModule from "@/store/modules/settings";
import rocEnrollModule from "@/store/modules/rocenroll";
import eventsModule from "@/store/modules/events";
import casesModule from "@/store/modules/cases";
import workflowsModule from "@/store/modules/workflows";
import investigationsModule from "@/store/modules/investigations";
import clustersModule from "@/store/modules/clusters";
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  modules: {
    auth: authModule,
    encounters: encountersModule,
    requests: requestsModule,
    cameras: camerasModule,
    watchlists: watchlistsModule,
    tools: toolsModule,
    settings: settingsModule,
    rocenroll: rocEnrollModule,
    events: eventsModule,
    cases: casesModule,
    workflows: workflowsModule,
    investigations: investigationsModule,
    clusters: clustersModule
  },
  plugins: [
    createPersistedState({
      key: "roc-watch-web",
      storage: window.localStorage,
      paths: [
        "auth",
        "cameras",
        "settings",
        "encounters.liveFeedCameras",
        "encounters.cameraLiveFeedState",
        "encounters.cameraLiveFeedMode",
        "encounters.searchText",
        "encounters.encounterFilterState",
        "encounters.dtFilterRange",
        "encounters.searchTemplateId",
        "encounters.showImageSearchBox",
        "encounters.cursor",
        "encounters.moreData",
        "encounters.matchesOnly",
        "encounters.adjudicationFilter",
        "encounters.analyticsFilter",
        "watchlists.encounterFilterSelectedWatchlists",
        "encounters.attributesFilter",
        "rocenroll.userConfig",
        "cases.cases",
        "cases.cameras",
        "settings/darkMode",
        "cases.activeMissionId",
        "cases.previousMissionId"
      ]
    }),
    // ROC Enroll uses session storage for the workflow configuration that is retrieved from the backend.
    // This is to allow page refreshes and other error scenarios to recover, but requires backend query when a new session is started.
    createPersistedState({
      key: "roc-enroll",
      storage: window.sessionStorage,
      paths: ["rocenroll.sessionConfig"]
    })
  ],
  state() {
    return {
      userId: ""
    };
  },
  getters: {
    userId(state) {
      return state.userId;
    }
  },
  actions: {
    resetAllDefaults({ commit }) {
      commit("auth/resetState", authModule.getDefaultState());
      commit("cameras/resetState", camerasModule.getDefaultState());
      commit("encounters/resetState", encountersModule.getDefaultState());
      commit("events/resetState", eventsModule.getDefaultState());
      commit("requests/resetState", requestsModule.getDefaultState());
      commit("rocenroll/resetState", rocEnrollModule.getDefaultState());
      commit("settings/resetState", settingsModule.getDefaultState());
      commit("tools/resetState", toolsModule.getDefaultState());
      commit("watchlists/resetState", watchlistsModule.getDefaultState());
      commit("cases/resetState", casesModule.getDefaultState());
      commit(
        "investigations/resetState",
        investigationsModule.getDefaultState()
      );
      commit("clusters/resetState", clustersModule.getDefaultState());
    },

    saveUserConfig({ getters, dispatch }) {
      let userConfig = getters["auth/userConfig"];

      if (!userConfig) {
        userConfig = {};
      }

      let settings = {
        activeMissionId: getters["cases/activeMissionId"],
        searchText: getters["encounters/getSearchText"],
        watchlists: getters["watchlists/encounterFilterSelectedWatchlists"],
        tags: getters["encounters/attributesFilter"],
        dateRangeFilter: getters["encounters/getDTFilter"],
        adjudication: getters["encounters/adjudicationFilter"],
        analytics: getters["encounters/analyticsFilter"],
        matchesOnly: getters["encounters/matchesOnly"],
        selectedFilterCameras:
          getters["cameras/encounterFilterSelectedCameras"],
        selectedLiveCameras: getters["encounters/liveFeedCameras"],
        displayType: getters["encounters/cameraLiveFeedMode"],
        toggleState: getters["encounters/cameraLiveFeedState"],
        newCaseDateTimeParser: getters["cases/getDateTimeParser"],
        darkMode: getters["settings/getDarkMode"],
        captureZonesInstructionsShown:
          getters["settings/getCaptureZonesInstructionsShown"]
      };

      userConfig.rwwSettings = settings;
      dispatch("auth/updateUserConfig", userConfig);
    }
  }
});

export default store;

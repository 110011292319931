import mutations from "./mutations.js";
import actions from "./actions.js";
import getters from "./getters.js";

const getDefaultState = () => {
  return {
    lastFetch: null,
    encounters: [],
    encountersTimeout: [],
    encounterFilterState: true,
    dtFilterRange: [],
    searchTemplateId: null,
    searchImageData: null,
    searchModality: "face",
    showImageSearchBox: false,
    showWarningToast: false,
    searchText: null,
    cursor: null,
    detectionAudioAlerts: undefined,
    toastModalities: undefined,
    toastTimeout: undefined,
    audioToPlay: undefined,
    moreData: false,
    liveFeedCameras: [],
    cameraLiveFeedState: "collapsed",
    cameraLiveFeedMode: "grid",
    matchesOnly: false,
    adjudicationFilter: [
      { label: "none" },
      { label: "confirmed" },
      { label: "rejected" }
    ],
    attributesFilter: [],
    allFaceAttributes: [],
    allObjectAttributes: [],
    analyticsFilter: [],
    liveFeedEnabled: true,
    maxLiveEncounters: 200,
    queryPageSize: 50
  };
};

const state = getDefaultState();

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
  getDefaultState
};

<template>
  <div>
    <div class="app-layout" id="main">
      <div class="d-flex" style="width: 100%;">
        <div v-if="isiOS" class="safe-space-inset" style="z-index: 99;"></div>
        <the-header v-if="showHeader" style="z-index: 99; width: 100%;" />
      </div>
      <SessionDialog />
      <create-a-mission
        v-if="hasNoMissions && showHeader"
        style="width: 100%"
      />

      <!-- this div can be used to establish a secondary header under our main header that sticks to the top such that the page content handles its own scrolling.
      example is how we're using this for the CameraLiveFeed in encounterslist - use <teleport to='#subHeader'> to bump the component into this top level div -->
      <div id="subHeader"></div>
      <router-view
        v-slot="slotProps"
        :style="coreStyle"
        style="flex-grow: 1; overflow: auto;"
      >
        <transition mode="out-in" :name="isTransitionEnabled ? 'route' : ''">
          <component :is="slotProps.Component" ref="childComponent"></component>
        </transition>
      </router-view>
    </div>
    <div v-show="showWatchlistImport">
      <WatchListDetails
        v-if="importerType != 'entitlements'"
        @close="importerClose"
        @newEnrollment="importerNewEnrollment"
      />
      <EntitlementImporter v-else @close="importerClose" />
    </div>
    <MinimizableDialog v-show="showCaseImport" @close="handleCloseCaseImporter">
      <template v-slot:header>
        <span style="font-size: 20px;">{{ caseImporterTitle }}</span>
      </template>
      <template v-slot="{ close }">
        <CaseImportWizard @close="handleCloseCaseImporter" :close="close" />
      </template>
    </MinimizableDialog>
  </div>
</template>

<script>
import {
  computed,
  onBeforeMount,
  onMounted,
  ref,
  watch,
  watchEffect
} from "vue";
import { useRouter } from "vue-router";
import TheHeader from "./components/layout/TheHeader.vue";
import CreateAMission from "./components/layout/CreateAMission.vue";
import MinimizableDialog from "@/components/ui/MinimizableDialog";
import CaseImportWizard from "@/components/cases/CaseImportWizard";
import { useStore } from "vuex";
import { PushNotifications } from "@capacitor/push-notifications";
import { debounce } from "lodash";
import WatchListDetails from "@/components/watchlists/WatchListDetails.vue";
import EntitlementImporter from "@/components/events/EntitlementImporter";
import SessionDialog from "@/components/settings/SessionDialog";

export default {
  name: "App",
  components: {
    TheHeader,
    WatchListDetails,
    MinimizableDialog,
    CaseImportWizard,
    EntitlementImporter,
    CreateAMission,
    SessionDialog
  },
  setup() {
    const store = useStore();
    const coreStyle = ref("");
    const showHeader = ref(false);
    const router = useRouter();

    // Only applies to Capacitor. Does not apply to iPad, as iPad cannot have its orientation locked by default.
    // Controlled by cordova-plugin-screen-orientation
    if (Object.keys(store.getters["settings/getDeviceInfo"]).length != 0) {
      window.screen.orientation.lock("portrait");
    }

    watchEffect(() => {
      // the empty '/' route indicates loading/redirecting, don't show header here either to avoid UI flashing/jumping
      let noHeaderPages = [
        "/login",
        "/notFound",
        "/",
        "/logout",
        "/dashboard",
        "/livefeedpopup",
        "/dashboard/",
        "/capturezoneseditor",
        "/clustervideopopup"
      ];
      showHeader.value =
        !noHeaderPages.includes(router.currentRoute.value.path) &&
        !router.currentRoute.value.path.endsWith("/clustervideopopup") &&
        !router.currentRoute.value.path.startsWith("/rocenroll");
    });

    const showWatchlistImport = computed(function() {
      return store.getters["watchlists/importerVisible"];
    });

    const importerType = computed(function() {
      return store.getters["watchlists/importerType"];
    });

    const showCaseImport = computed(function() {
      return store.getters["cases/importerVisible"];
    });

    const userSettingsChanged = computed(function() {
      return store.getters["auth/userSettingsChanged"];
    });

    //to save user config more often, this watch noticies any changes from
    //enounter filter/live cams, and if a value changes in there, this watch
    //waits 10 seconds and then saves the config as to not make db calls too often
    watch(
      userSettingsChanged,
      debounce(() => {
        let loggedIn = store.getters["auth/isAuthenticated"];
        if (loggedIn === true) {
          store.dispatch("saveUserConfig");
        }
      }, 10000)
    );

    watch(showWatchlistImport, () => {
      if (showWatchlistImport.value === true) {
        coreStyle.value = "opacity: .4;";
        document.addEventListener("keydown", handleEscapePress, true);
      } else {
        coreStyle.value = "";
        document.removeEventListener("keydown", handleEscapePress, true);
      }
    });

    function handleEscapePress(event) {
      if (event.code === "Escape") {
        store.dispatch("watchlists/toggleWatchlistImporter", false);
      }
    }

    onMounted(async () => {
      try {
        //handles page refresh - resets refreshToken callback if already set
        if (
          !store.getters["auth/isSSO"] &&
          store.getters["auth/isAuthenticated"] &&
          store.getters["auth/lastRefreshDelay"] &&
          store.getters["auth/lastRefreshTimeout"]
        ) {
          console.debug(
            "lastRefreshDelay: ",
            store.getters["auth/lastRefreshDelay"]
          );
          console.debug(
            "lastRefreshTimeout: ",
            store.getters["auth/lastRefreshTimeout"]
          );
          console.debug("date.now: ", Date.now());
          const refreshTimeoutMS = Math.max(
            store.getters["auth/lastRefreshDelay"] -
              (Date.now() - store.getters["auth/lastRefreshTimeout"]),
            5000
          );
          console.debug("calculated refreshTimeoutMS: ", refreshTimeoutMS);
          const refreshTimerHandle = await store.dispatch(
            "auth/setAutoRefreshToken",
            { refresh_delay: refreshTimeoutMS }
          );
          if (refreshTimerHandle) {
            console.debug(
              "successfully rescheduled refreshTimer: ",
              refreshTimerHandle
            );
            console.debug(
              "lastRefreshTime: ",
              store.getters["auth/lastRefreshTimeout"]
            );
          }
        } else {
          console.debug("not authenticated. Skipping refreshTimer reschedule");
        }
        if (store.getters["auth/isAuthenticated"]) {
          // if already logged in, fetch here, otherwise will fetch upon successful login
          await store.dispatch("cases/loadCases");
          await store.dispatch("settings/getUXSetting");
          await store.dispatch("rocenroll/loadEnrollConfig");
          await store.dispatch("settings/loadUserGroups");
        }

        await store.dispatch("settings/getDeviceInfo");
        const addListeners = async () => {
          await PushNotifications.addListener("registration", (token) => {
            console.info("Registration token: ", token.value);
            store.commit("settings/setDeviceToken", token.value);
          });

          await PushNotifications.addListener("registrationError", (err) => {
            console.error("Registration error: ", err.error);
          });

          await PushNotifications.addListener(
            "pushNotificationReceived",
            (notification) => {
              console.log("Push notification received: ", notification);
            }
          );

          await PushNotifications.addListener(
            "pushNotificationActionPerformed",
            (notification) => {
              try {
                console.log(
                  "Push notification action performed",
                  notification.actionId,
                  notification.inputValue
                );
                console.log("raw notification: ", notification);
                const watchlistedFaceMatchId =
                  notification.notification.data._id;
                router.push({
                  name: "EncounterWatchlistMatch",
                  params: { id: watchlistedFaceMatchId }
                });
              } catch (err) {
                console.log("pushNotificationActionPerformed ERROR: ", err);
              }
            }
          );
        };

        const registerNotifications = async () => {
          console.log("registerNotifications");
          let permStatus = await PushNotifications.checkPermissions();

          if (permStatus.receive === "prompt") {
            permStatus = await PushNotifications.requestPermissions();
          }

          if (permStatus.receive !== "granted") {
            throw new Error("User denied permissions!");
          }

          await PushNotifications.register();
        };

        const getDeliveredNotifications = async () => {
          console.log("getDeliveredNotifications");
          const notificationList = await PushNotifications.getDeliveredNotifications();
          console.log("delivered notifications", notificationList);
        };

        await addListeners();
        await registerNotifications();
      } catch (err) {
        //console.log(err);
      }
      //add listener for dark mode preference change from users browser/computer
      window
        .matchMedia("(prefers-color-scheme: dark)")
        ?.addEventListener("change", setTheme());
    });

    const isiOS = computed(function() {
      try {
        const model = store.getters["settings/getDeviceInfo"]["model"];
        return (
          model.toLowerCase().includes("iphone") ||
          model.toLowerCase().includes("ipad")
        );
      } catch {
        return false;
      }
    });

    // update this for situations where we don't want page transition animation effects between routes
    const isTransitionEnabled = computed(() => {
      // disable Transition animation effect for ROC Enroll pages
      // ROC Enroll performs a lot of dynamic page changes, so the transition animation ruins the UX given there was no user input to trigger the transition.
      // the transition animation seems more relevant for page transitions based on a user click
      return !router.currentRoute.value.path.startsWith("/rocenroll");
    });

    // create a component reference here so we can call into the component for top level events.
    // this is used for the WatchlistImporter, which emits 'close' and 'newEnrollment' in some scenarios
    // we'd want these events to be handled by the component using the WatchlistImporter
    // TODO currently - just call that component's handler if defined.  This requires a specific function name.  Not sure if this is the best solution.
    const childComponent = ref(null);
    function importerClose() {
      if (childComponent.value && childComponent.value.importerClose) {
        childComponent.value.importerClose();
      }
    }
    function importerNewEnrollment(payload) {
      if (childComponent.value && childComponent.value.importerNewEnrollment) {
        childComponent.value.importerNewEnrollment(payload);
      }
    }

    function handleCloseCaseImporter() {
      store.commit("cases/setImporterVisible", false);
    }

    const caseImporterTitle = computed(() => {
      var editingCase = store.getters["cases/editingCase"];
      if (editingCase) {
        return `Add Files`;
      } else {
        return "New Case";
      }
    });

    function setTheme() {
      setDarkMode(store.getters["settings/getDarkMode"]);
    }
    function setDarkMode(enabled) {
      // the empty '/' route indicates loading/redirecting, dont set any mode yet
      if (router.currentRoute.value.path === "/") {
        return;
      }
      document.documentElement.setAttribute(
        "data-theme",
        enabled ? "darkMode" : "lightMode"
      );
    }
    watch(
      () => store.getters["settings/getDarkMode"],
      () => {
        if (store.getters["settings/getDarkMode"] === true) {
          setDarkMode(true);
        } else if (store.getters["settings/getDarkMode"] === false) {
          setDarkMode(false);
        } else {
          // user hasn't set a preference
        }
      }
    );

    const hasNoMissions = computed(() => {
      return (
        store.getters["auth/isAuthenticated"] &&
        store.getters["cases/loadedOnce"] &&
        store.getters["cases/cases"].length == 0
      );
    });

    return {
      showWatchlistImport,
      coreStyle,
      showHeader,
      isiOS,
      isTransitionEnabled,
      childComponent,
      importerClose,
      importerNewEnrollment,
      showCaseImport,
      handleCloseCaseImporter,
      caseImporterTitle,
      userSettingsChanged,
      importerType,
      hasNoMissions
      // darkModeToggle
    };
  }
};
</script>

<style lang="scss">
/* FK Grotesk Normal */
@font-face {
  font-family: "FK Grotesk";
  src: ("./fonts/FKGrotesk/FKGrotesk-Regular.eot");
  src: url("./fonts/FKGrotesk/FKGrotesk-Regular.eot")
      format("embedded-opentype"),
    url("./fonts/FKGrotesk/FKGrotesk-Regular.woff2") format("woff2"),
    url("./fonts/FKGrotesk/FKGrotesk-Regular.woff") format("woff"),
    url("./fonts/FKGrotesk/FKGrotesk-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* FK Grotesk Normal Italic */
@font-face {
  font-family: "FK Grotesk";
  src: ("./fonts/FKGrotesk/FKGrotesk-Italic.eot");
  src: url("./fonts/FKGrotesk/FKGrotesk-Italic.eot") format("embedded-opentype"),
    url("./fonts/FKGrotesk/FKGrotesk-Italic.woff2") format("woff2"),
    url("./fonts/FKGrotesk/FKGrotesk-Italic.woff") format("woff"),
    url("./fonts/FKGrotesk/FKGrotesk-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

/* FK Grotesk Medium */
@font-face {
  font-family: "FK Grotesk";
  src: ("./fonts/FKGrotesk/FKGrotesk-Medium.eot");
  src: url("./fonts/FKGrotesk/FKGrotesk-Medium.eot") format("embedded-opentype"),
    url("./fonts/FKGrotesk/FKGrotesk-Medium.woff2") format("woff2"),
    url("./fonts/FKGrotesk/FKGrotesk-Medium.woff") format("woff"),
    url("./fonts/FKGrotesk/FKGrotesk-Medium.ttf") format("truetype");
  font-weight: medium;
  font-style: normal;
}

/* FK Grotesk Bold */
@font-face {
  font-family: "FK Grotesk";
  src: ("./fonts/FKGrotesk/FKGrotesk-Bold.eot");
  src: url("./fonts/FKGrotesk/FKGrotesk-Bold.eot") format("embedded-opentype"),
    url("./fonts/FKGrotesk/FKGrotesk-Bold.woff2") format("woff2"),
    url("./fonts/FKGrotesk/FKGrotesk-Bold.woff") format("woff"),
    url("./fonts/FKGrotesk/FKGrotesk-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

/* FK Grotesk Bold Italic */
@font-face {
  font-family: "FK Grotesk";
  src: ("./fonts/FKGrotesk/FKGrotesk-BoldItalic.eot");
  src: url("./fonts/FKGrotesk/FKGrotesk-BoldItalic.eot")
      format("embedded-opentype"),
    url("./fonts/FKGrotesk/FKGrotesk-BoldItalic.woff2") format("woff2"),
    url("./fonts/FKGrotesk/FKGrotesk-BoldItalic.woff") format("woff"),
    url("./fonts/FKGrotesk/FKGrotesk-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins"),
    url(./fonts/Poppins/Poppins-Medium.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "PoppinsLight";
  src: local("PoppinsLight"),
    url(./fonts/Poppins/Poppins-Light.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsSemiBold";
  src: local("PoppinsSemiBold"),
    url(./fonts/Poppins/Poppins-SemiBold.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url(./fonts/Poppins/Poppins-Bold.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("Poppins"),
    url(./fonts/Poppins/Poppins-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsMedium";
  src: local("PoppinsMedium"),
    url(./fonts/Poppins/Poppins-Medium.ttf) format("truetype");
}

@font-face {
  font-family: "Inter";
  src: local("Inter"), url(./fonts/Inter/Inter-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Aeonik";
  src: local("Aeonik"),
    url(./fonts/Aeonik/Aeonik-Regular.otf) format("truetype");
}

@font-face {
  font-family: "MTNBrighterSans";
  src: local("MTNBrighterSans"),
    url(./fonts/MTNBrighterSans/MTNBrighterSans-Regular.otf) format("truetype");
}

@font-face {
  font-family: "MTNBrighterSansLight";
  src: local("MTNBrighterSans"),
    url(./fonts/MTNBrighterSans/MTNBrighterSans-Light.otf) format("truetype");
}

@font-face {
  font-family: "MTNBrighterSansMedium";
  src: local("MTNBrighterSans-Medium"),
    url(./fonts/MTNBrighterSans/MTNBrighterSans-Medium.otf) format("truetype");
}

@font-face {
  font-family: "MTNBrighterSansExtraBold";
  src: local("MTNBrighterSans-ExtraBold"),
    url(./fonts/MTNBrighterSans/MTNBrighterSans-ExtraBold.otf)
      format("truetype");
}

* {
  box-sizing: border-box;
}

html {
  font-family: FK Grotesk, Helvetica, Arial, sans-serif;
}

body {
  margin: 0;

  /* Backup, but ideally everyone should be using latest version of browsers */
  height: 100vh;

  /* Dynamic viewport height, new css feature */
  height: 100dvh;

  max-height: -webkit-fill-available;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-background);
}

#app {
  font-family: FK Grotesk, Helvetica, Arial, sans-serif;

  height: 100%;
}

/* START MDB Styling */
/* MDB workarounds - will go away when UI Refresh is complete */
input.form-control {
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  border: solid 1px var(--overwatch-neutral-300);
}

input.form-control:focus {
  color: var(--overwatch-neutral-100);
}

input.form-control::placeholder {
  color: var(--overwatch-neutral-300) !important;
}

.form-outline .form-control:focus ~ .form-label,
.form-outline .form-control.active ~ .form-label {
  color: var(--overwatch-neutral-100);
}

textarea.form-control {
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  border: solid 1px var(--overwatch-neutral-300);
}

textarea.form-control:focus {
  color: var(--overwatch-neutral-100);
}

.form-outline .form-control {
  background-color: var(--overwatch-neutral-500);
}

.form-outline .form-control:disabled {
  background-color: var(--overwatch-neutral-400);
  color: var(--overwatch-neutral-300);
}

textarea.form-control::placeholder {
  color: var(--overwatch-neutral-300) !important;
}

/* MDBInput */
/* from: https://mdbootstrap.com/support/standard/change-border-color-on-focus-input/ */
.form-outline .form-control:focus ~ .form-notch .form-notch-leading {
  border-top: 1px solid var(--overwatch-primary);
  border-bottom: 1px solid var(--overwatch-primary);
  border-left: 1px solid var(--overwatch-primary);
}

.form-outline .form-control:focus ~ .form-notch .form-notch-trailing {
  border-top: 1px solid var(--overwatch-primary);
  border-right: 1px solid var(--overwatch-primary);
  border-bottom: 1px solid var(--overwatch-primary);
}

.form-outline .form-control:focus ~ .form-notch .form-notch-middle {
  border-top: 0;
  border-bottom: 1px solid var(--overwatch-primary);
}

.form-outline .form-control ~ .form-label {
  color: var(--overwatch-neutral-300);
}

.form-outline .form-control:focus ~ .form-label {
  color: var(--overwatch-primary);
}

/* MDBRange */
/* TODO - color toggle needs changing */
.form-range {
  background-color: transparent;
  border: none;
  outline: none;
}

.form-label {
  color: var(--overwatch-neutral-100);
}

.range .thumb:after {
  background: var(--overwatch-primary);
}

input[type="range"]::-webkit-slider-thumb {
  background: var(--overwatch-primary);
}
input[type="range"]::-webkit-slider-thumb:active {
  background: var(--overwatch-primary);
  opacity: 0.6;
}

input[type="range"]::-moz-range-thumb {
  background: var(--overwatch-primary);
}
input[type="range"]::-moz-range-thumb:active {
  background: var(--overwatch-primary);
  opacity: 0.6;
}

input[type="range"]::-ms-thumb {
  background: var(--overwatch-primary);
}
input[type="range"]::-ms-thumb:active {
  background: var(--overwatch-primary);
}

/* MDBProgress */
.progress-bar {
  background-color: var(--overwatch-primary);
  color: var(--overwatch-button-text);
  border-radius: 10px;
}

.progress {
  border-radius: 10px;
}

/* MDBStepper */
.stepper-completed .stepper-head-icon {
  background-color: var(--overwatch-primary);
}

.stepper-active .stepper-head-icon {
  background-color: var(--overwatch-neutral-500);
}

/* MDBSelect */
.select-wrapper .select-arrow {
  color: var(--overwatch-primary);
}

.select-input.focused ~ .select-arrow {
  color: var(--overwatch-primary);
}

.select-input.form-control {
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  border: solid 1px var(--overwatch-neutral-300);
}

.select-input.form-control:focus {
  border: solid 1px var(--overwatch-primary);
}

.form-control .select-input.focused.active {
  border: 1px solid var(--overwatch-primary);
}

.select-input.focused ~ .form-notch .form-notch-leading {
  border: 1px solid var(--overwatch-primary);
}

.select-input.focused ~ .form-notch .form-notch-middle {
  border: 1px solid var(--overwatch-primary);
}

.select-wrapper .select-input.focused ~ .form-notch .form-notch-trailing {
  border: 1px solid var(--overwatch-primary);
}

.input-group > .form-control {
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  border: 1px solid var(--overwatch-neutral-300);
}

.input-group > .form-control:focus {
  border: 1px solid var(--overwatch-primary);
  box-shadow: none;
}

.input-group {
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
}

.select-option {
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
}

.select-option.selected.active {
  color: var(--overwatch-button-text);
  background-color: var(--overwatch-primary);
}

.select-option.selected:focus {
  color: var(--overwatch-button-text);
  background-color: var(--overwatch-primary);
}

.select-option:hover:not(.disabled) {
  color: var(--overwatch-button-text);
  background-color: var(--overwatch-primary);
}

.select-no-results {
  color: var(--overwatch-button-text);
}

/* MDB DatePicker */
.datepicker-header {
  background-color: var(--overwatch-primary);
}

.datepicker-cell .datepicker-cell-content {
  border: none !important;
}

.datepicker-cell:hover {
  border: none !important;
  color: var(--overwatch-button-text);
}

.datepicker-cell.selected .datepicker-cell-content {
  background-color: var(--overwatch-primary);
  border: none;
  color: var(--overwatch-button-text);
}

.datepicker-footer-btn {
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-secondary);
}

.datepicker-footer-btn:hover {
  color: var(--overwatch-primary);
  background-color: var(--overwatch-secondary);
}

.datepicker-toggle-button:hover {
  color: var(--overwatch-primary);
}

.datepicker-toggle-button:focus {
  color: var(--overwatch-primary);
}

.datepicker-toggle-button {
  color: var(--overwatch-neutral-100);
}

.datepicker-main {
  background-color: var(--overwatch-secondary);
}

.datepicker-date-controls .datepicker-view-change-button {
  color: var(--overwatch-neutral-100);
}

.datepicker-date-controls .datepicker-view-change-button:hover {
  color: var(--overwatch-primary);
  background-color: var(--overwatch-secondary);
}

.datepicker-date-controls
  .datepicker-arrow-controls
  .datepicker-previous-button,
.datepicker-date-controls .datepicker-arrow-controls .datepicker-next-button {
  color: var(--overwatch-neutral-100);
}

.datepicker-date-controls
  .datepicker-arrow-controls
  .datepicker-previous-button:hover,
.datepicker-date-controls
  .datepicker-arrow-controls
  .datepicker-next-button:hover {
  color: var(--overwatch-neutral-500);
}
.datepicker-date-controls .datepicker-view-change-button {
  color: var(--overwatch-neutral-100);
}

/* MDB btn-link coloring */
.btn-link {
  color: var(--overwatch-primary);
}
.btn-link:hover {
  color: var(--overwatch-primary);
  background-color: var(--overwatch-button-hover);
}

/* MDB Radio Button */
.form-check-input:checked {
  border-color: var(--overwatch-primary);
  box-shadow: 0;
}

.form-check-input:checked:focus:before {
  box-shadow: 0px 0px 0px 13px var(--overwatch-primary);
  transform: scale(1);
  transition: box-shadow 0.2s, transform 0.2s;
}

.form-check-input[type="radio"]:checked:after {
  background-color: var(--overwatch-primary);
  border-color: var(--overwatch-primary);
  box-shadow: 0;
}

.form-check-input:checked:focus {
  border-color: var(--overwatch-primary);
  box-shadow: 0;
}

.MDBDropdownToggle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: black;
}

.dropdown-divider {
  margin: 0;
}

/* END MDB stlying */

.route-enter-from {
  opacity: 0;
  transform: translateY(-30px);
}

.route-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

.route-enter-active {
  transition: all 0.3s ease-out;
}

.route-leave-active {
  transition: all 0.3s ease-in;
}

.route-enter-to,
.route-leave-from {
  opacity: 1;
  transform: translateY(0);
}

input {
  /* background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  border: solid 1px var(--overwatch-neutral-300); */
  border-radius: 5px;
}

input::placeholder {
  color: var(--overwatch-neutral-300) !important;
}

textarea {
  background-color: var(--overwatch-neutral-500);
  color: var(--overwatch-neutral-100);
  border: solid 1px var(--overwatch-neutral-300);
}

textarea::placeholder {
  color: var(--overwatch-neutral-300) !important;
}

input:focus,
select:focus,
textarea:focus {
  outline: none;
}

.footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
}

/* for iphone safe-space */
.safe-space-inset {
  height: env(safe-area-inset-top);
  background-color: var(--overwatch-primary);
}

.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}

.multiselect-tag {
  max-width: 25ch;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 1;
}

.multiselect-placeholder {
  @include overwatch-body-small;
  color: var(--overwatch-neutral-300);
}

.app-layout {
  display: flex;
  flex-direction: column;
  /* Backup, but ideally everyone should be using latest version of browsers */
  height: 100vh;
  /* Dynamic viewport height, new css feature */
  height: 100dvh;
  max-height: -webkit-fill-available;
}

/* Width */
::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

/* Track */
//Removed scrollbars within input boxes - Brittany
::-webkit-scrollbar-track {
  display: none;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--overwatch-primary);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--overwatch-button-hover);
}
</style>

<template>
  <div>
    <div class="overwatch-title-med title">
      Select Search Galleries
    </div>
    <div
      style="display: flex; align-items: center; gap: var(--spacing-s); margin-top: var(--spacing-xl);"
    >
      <RocCheckbox v-model="isSelectAllChecked" />
      Select All
    </div>
    <div class="title-row">
      <div class="overwatch-title-small">
        Watchlists
      </div>
    </div>
    <div class="cards">
      <WatchlistView
        v-for="watchlist of watchlists"
        selectMode
        @check="(isChecked) => handleWatchlistCheck(watchlist, isChecked)"
        :id="watchlist._id"
        :name="watchlist.name"
        :size="watchlist.size"
        :enabled="watchlist.enabled"
        :member-summary="watchlist.memberSummary"
        :match-threshold="watchlist.matchThreshold"
        :audible-alarm="watchlist.audibleAlarmEnabled"
        :wl-type="watchlist.type"
        :color="watchlist.color"
        :watchlist="watchlist"
        :isSelected="isAllSelected"
        style="margin:0;"
      />
    </div>
    <div class="title-row">
      <div class="overwatch-title-small">
        Evidentiary Media
      </div>
      <RocButton @click="mediaRef.click()">
        Upload Media
      </RocButton>
      <input
        ref="mediaRef"
        type="file"
        style="display: none"
        @change="addMediaFiles($event)"
        multiple
        accept="image/*, video/*"
      />
    </div>
    <div @click="isShowingVideos = !isShowingVideos" class="collapse-label">
      Videos
      <RocIcon
        icon="downArrow"
        :size="'sm'"
        :color="'black'"
        style="transition: transform 0.1s ease;"
        :style="{
          transform:
            isShowingVideosIcon === 'caret-up'
              ? 'rotate(180deg)'
              : 'rotate(0deg)'
        }"
      />
    </div>
    <MDBCollapse
      v-model="isShowingVideos"
      :class="{ 'fit-content': isShowingVideos }"
    >
      <div class="cards case-media">
        <CaseMediaCard
          v-for="(media, index) of videoMediaFiles"
          :key="media._id"
          :media="media"
          :uploading="media.isUploading"
          @check="(check) => handleMediaCheck(media, check)"
          @delete="deleteMedia(media)"
          :isSelected="isAllSelected"
          style="width: 600px;"
        />
        <div v-if="videoMediaFiles.length === 0">
          <RocIcon icon="cameraOff" size="sm" />
          No videos uploaded.
        </div>
      </div>
    </MDBCollapse>
    <div @click="isShowingImages = !isShowingImages" class="collapse-label">
      Images
      <RocIcon
        icon="downArrow"
        :size="'sm'"
        :color="'black'"
        style="transition: transform 0.1s ease;"
        :style="{
          transform:
            isShowingImagesIcon === 'caret-up'
              ? 'rotate(180deg)'
              : 'rotate(0deg)'
        }"
      />
    </div>
    <MDBCollapse
      v-model="isShowingImages"
      :class="{ 'fit-content': isShowingImages }"
    >
      <div class="cards case-media">
        <CaseMediaCard
          v-for="(media, index) of imageMediaFiles"
          :key="media._id"
          :media="media"
          :uploading="media.isUploading"
          @check="(check) => handleMediaCheck(media, check)"
          @delete="deleteMedia(media)"
          :isSelected="isAllSelected"
          style="width: 600px;"
        />
        <div v-if="imageMediaFiles.length === 0">
          <RocIcon icon="cameraOff" size="sm" />
          No images uploaded.
        </div>
      </div>
    </MDBCollapse>
  </div>
</template>

<script>
import { onMounted, ref, computed, watch, nextTick } from "vue";
import { useStore } from "vuex";
import RocButton from "@/components/ui/RocButton";
import RocCheckbox from "@/components/ui/RocCheckbox";
import WatchlistView from "@/components/watchlists/WatchlistView";
import CaseMediaCard from "@/components/investigations/CaseMediaCard";
import { MDBCard, MDBCardBody, MDBCheckbox, MDBCollapse } from "mdb-vue-ui-kit";
import RocIcon from "@/components/ui/RocIcon";

export default {
  name: "InvestigativeCaseGallerySelection",
  components: {
    RocButton,
    RocCheckbox,
    MDBCard,
    MDBCardBody,
    MDBCheckbox,
    WatchlistView,
    CaseMediaCard,
    MDBCollapse,
    RocIcon
  },
  props: ["case"],
  emits: ["select-media", "select-watchlist"],
  setup(props, context) {
    const store = useStore();

    const currentCase = computed(() => props.case);

    const caseId = computed(() => {
      if (currentCase.value) {
        return currentCase.value._id;
      }
    });

    watch(caseId, async (nv) => {
      if (nv) {
        await populateMediaFilesList();
      }
    });

    onMounted(async () => {
      await loadWatchlists();
    });

    async function populateMediaFilesList() {
      const response = await store.dispatch(
        "investigations/getCaseMediaByCaseId",
        { caseId: caseId.value }
      );

      if (response.status === "success") {
        for (let m of response.result) {
          var type = m.mimetype.split("/")[0];
          mediaFiles.value.push({
            _id: m._id,
            name: m.fileName,
            type: type,
            isUploading: ref(false)
          });
        }
      }
    }

    const mediaRef = ref(null);

    const mediaFiles = ref([]);
    const selectedMediaFiles = ref([]);

    watch(selectedMediaFiles, (nv) => {}, { deep: true });

    async function addMediaFiles(e) {
      const newFiles = e.target.files;

      const newlyUploadedMediaFiles = []; // Tracking the newly uploaded files

      // Do it under two for loops so that all files are shown visually.
      for (let file of newFiles) {
        const mediaFileObj = {};

        mediaFileObj.name = file.name;
        mediaFileObj.type = file.type.split("/")[0];
        mediaFileObj.isUploading = ref(true);

        mediaFiles.value.push(mediaFileObj);

        newlyUploadedMediaFiles.push(mediaFileObj);
      }

      let i = 0;
      for (let file of newFiles) {
        const response = await uploadMediaFile(file);
        if (response.status === "success") {
          newlyUploadedMediaFiles[i]._id = response.result._id;
          newlyUploadedMediaFiles[i].isUploading.value = false;
        } else {
          // TODO: Failed upload
        }
        i++;
      }
    }

    async function uploadMediaFile(file) {
      // Sean: Create Camera that Media will be associated with.
      // One Camera per Media, recommended by Josh
      var cameraId;

      let payload = await createCameraPayload({
        name: `${currentCase.value.name}_cam_${file.name}`,
        analytics: ["face"]
      });
      var response = await store.dispatch("cases/createCamera", payload);

      if (response.status === "success") {
        cameraId = response.result._id;
      }

      // Create Media
      payload = {
        caseId: caseId.value,
        cameraId: cameraId,
        startTime: new Date(0),
        fileName: file.name,
        file: file
      };

      response = await store.dispatch("cases/uploadMedia", payload);
      return response;
    }

    async function createCameraPayload(tempCameraObject) {
      let newDoc = {};
      let vsConfig;
      newDoc.name = tempCameraObject.name;
      newDoc.enabled = tempCameraObject.enabled;

      const result = await store.dispatch(
        "cameras/getVSConfigDefaultByModality",
        "face"
      );
      if (result.status === "success") {
        vsConfig = result.value;
      }

      newDoc.vs_config = vsConfig;

      newDoc.matchThresholdOverride = {
        enabled: false,
        threshold: 0.8
      };

      newDoc.caseId = caseId.value;

      return newDoc;
    }

    async function loadWatchlists(refresh = false) {
      try {
        await store.dispatch("watchlists/loadWatchlists", {
          forceRefresh: refresh
        });
      } catch (error) {
        error.value = error.message || "Something went wrong!";
      }
    }

    const watchlists = computed(function() {
      return store.getters["watchlists/watchlists"];
    });

    const selectedWatchlists = ref([]);

    function handleMediaCheck(media, isChecked) {
      const id = media._id;
      if (isChecked) {
        if (!selectedMediaFiles.value.includes(media)) {
          selectedMediaFiles.value.push(media);
        }
      } else {
        isSelectAllChecked.value = false;
        const index = selectedMediaFiles.value.indexOf(media);
        if (index > -1) {
          selectedMediaFiles.value.splice(index, 1);
        }
      }
    }

    function handleWatchlistCheck(watchlist, isChecked) {
      const id = watchlist._id;
      if (isChecked) {
        if (!selectedWatchlists.value.includes(watchlist)) {
          selectedWatchlists.value.push(watchlist);
        }
      } else {
        isSelectAllChecked.value = false;
        const index = selectedWatchlists.value.indexOf(watchlist);
        if (index > -1) {
          // only splice array when item is found
          selectedWatchlists.value.splice(index, 1); // 2nd parameter means remove one item only
        }
      }
    }

    async function deleteMedia(media) {
      const response = await store.dispatch("investigations/deleteMedia", {
        caseId: caseId.value,
        mediaId: media._id
      });
      if (response.status === "success") {
        const index = mediaFiles.value.findIndex((v) => {
          return v._id === media._id;
        });

        if (index >= 0) {
          mediaFiles.value.splice(index, 1);
        }
      }
    }

    watch(
      selectedMediaFiles,
      (nv) => {
        context.emit("select-media", nv);
      },
      { deep: true }
    );

    watch(
      selectedWatchlists,
      (nv) => {
        context.emit("select-watchlist", nv);
      },
      { deep: true }
    );

    const isAllSelected = ref(0);
    const isSelectAllChecked = ref(false);
    // Two variables is intentional

    watch(isSelectAllChecked, (nv) => {
      if (nv) {
        isAllSelected.value = 1;
      } else {
        if (
          selectedWatchlists.value.length === watchlists.value.length &&
          selectedMediaFiles.value.length === mediaFiles.value.length
        ) {
          isAllSelected.value = -1;
        } else {
          isAllSelected.value = 0;
        }
      }
    });

    const isShowingImages = ref(true);
    const isShowingVideos = ref(true);

    const imageMediaFiles = computed(() => {
      return mediaFiles.value.filter((m) => m.type === "image");
    });

    const videoMediaFiles = computed(() => {
      return mediaFiles.value.filter((m) => m.type === "video");
    });

    const isShowingImagesIcon = computed(() => {
      return isShowingImages.value ? "caret-up" : "caret-down";
    });

    const isShowingVideosIcon = computed(() => {
      return isShowingVideos.value ? "caret-up" : "caret-down";
    });

    return {
      mediaRef,
      mediaFiles,
      addMediaFiles,
      watchlists,
      handleMediaCheck,
      handleWatchlistCheck,
      deleteMedia,
      isAllSelected,
      isSelectAllChecked,
      isShowingImages,
      isShowingVideos,
      imageMediaFiles,
      videoMediaFiles,
      isShowingImagesIcon,
      isShowingVideosIcon
    };
  }
};
</script>

<style scoped lang="scss">
.title-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: var(--spacing-xl);

  gap: var(--spacing-s);
}

.title {
  @include overwatch-title-med;
}

.cards {
  margin-top: var(--spacing-s);
  display: grid;
  width: fit-content;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-s);
}

.collapse-label {
  margin-top: var(--spacing-m);
  cursor: pointer;
  user-select: none;
}

.fit-content {
  height: fit-content !important;
}
</style>

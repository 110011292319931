<script setup>
import { ref, computed, watchEffect, watch } from "vue";
import { useStore } from "vuex";
import RocIcon from "./RocIcon.vue";
import { useRoute } from "vue-router";

/**
 * There are currently three (3) logo sources covered by this logic:
 * 1. product name based logo from static assets
 * 2. custom whitelabel logo from the preAuthConfig
 * 3. custom whitelabel logo for ROC Enroll from the flavor config
 */
const props = defineProps({
  width: {
    type: String,
    default: "250px"
  },
  height: {
    type: String,
    default: "150px"
  },
  product: {
    type: String,
    default: null,
    validator: (value) => {
      return [null, "watch", "examine", "enroll", "powered"].includes(value);
    }
  },
  enrollFlavor: {
    type: String,
    default: null
  }
});

const store = useStore();
const route = useRoute();

const logoSrc = ref(null);
const isWhiteLabelEnabled = ref(false);
const rocLogo = ref("rocLogo");

const preAuthConfig = computed(() => store.getters["auth/preAuthConfig"]);

watchEffect(() => {
  const logoMap = {
    examine: "ROC_Examine",
    enroll: "ROC_Enroll",
    powered: "ROCPowered"
  };

  if (props.product !== null) {
    rocLogo.value = logoMap[props.product] ?? "rocLogo";
  } else {
    const url = route.fullPath.split("/")[1];
    rocLogo.value = logoMap[url] ?? "rocLogo";
  }
});

watchEffect(async () => {
  if (props.enrollFlavor) {
    logoSrc.value = props.enrollFlavor;
  } else if (preAuthConfig.value) {
    logoSrc.value = await store.dispatch("settings/getLogo", {
      preAuthConfig: preAuthConfig.value
    });
    isWhiteLabelEnabled.value =
      store.getters["auth/preAuthConfig"].isWhiteLabelEnabled;
  }
});
</script>

<template>
  <div class="logoHolder">
    <RocIcon
      v-if="!isWhiteLabelEnabled && !enrollFlavor"
      :icon="rocLogo"
      color="black"
      size="match"
    />
    <img
      v-else-if="logoSrc !== null && logoSrc !== ''"
      :src="logoSrc"
      style="width: 100%; max-height: 100%; object-fit: contain;"
      alt="Product Logo"
    />
  </div>
</template>

<style scoped>
.logoHolder {
  display: flex;
  width: v-bind(width);
  height: v-bind(height);
  max-height: v-bind(height);
  margin-left: auto;
  margin-right: auto;
}
</style>

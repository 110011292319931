<template>
  <!-- this is used as popper menu, MyPopperWrapper.vue is the hover info popper component -->
  <Popper
    class="main-div"
    :class="{
      'menu-popper': popperType === 'menu',
      'tooltip-popper': popperType === 'tooltip'
    }"
    v-bind="$attrs"
    :openDelay="openDelay"
    :closeDelay="closeDelay"
    zIndex="999"
    tabindex="0"
  >
    <div class="trigger">
      <slot />
    </div>
    <template #content="props">
      <slot name="content" v-bind="props" />
    </template>
  </Popper>
</template>

<script>
import { ref } from "vue";
import Popper from "vue3-popper";

export default {
  name: "RocPopper",
  props: {
    openDelay: {
      type: String,
      default: "0"
    },
    closeDelay: {
      type: String,
      default: "0"
    },
    popperType: {
      type: String,
      default: "freestyle"
    }
  },
  components: {
    Popper
  },
  setup(props, context) {
    const isShowingPopper = ref(null);
    return {
      isShowingPopper
    };
  }
};
</script>

<style scoped lang="scss">
/*main-div = common styling across different modes*/
.main-div {
  @include overwatch-body-med;
  height: fit-content !important;
  width: fit-content !important;
}

/* Hide parts of box shadow depending on popper direction */
.main-div :deep(.popper[data-popper-placement="top"] #arrow::before) {
  -webkit-clip-path: inset(0px -4px -4px 0px);
  clip-path: inset(0px -4px -4px 0px);
}

.main-div :deep(.popper[data-popper-placement="right"] #arrow::before) {
  -webkit-clip-path: inset(0px 0px -4px -4px);
  clip-path: inset(0px 0px -4px -4px);
}

.main-div :deep(.popper[data-popper-placement="bottom"] #arrow::before) {
  -webkit-clip-path: inset(-4px 0px 0px -4px);
  clip-path: inset(-4px 0px 0px -4px);
}

.main-div :deep(.popper[data-popper-placement="left"] #arrow::before) {
  -webkit-clip-path: inset(-4px -4px 0px 0px);
  clip-path: inset(-4px -4px 0px 0px);
}

/*trigger is the content shown on the page that can trigger the popper*/
.trigger {
  height: fit-content;
  width: fit-content;
  line-height: 0px;
}

/*tooltip mode styling*/
.tooltip-popper :deep(.popper) {
  @include overwatch-body-small;
  background-color: var(--overwatch-primary);
  color: var(--overwatch-button-text);
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  padding: $--spacing-m;
  position: relative;
  min-width: 250px;
}

.tooltip-popper :deep(.popper #arrow::before) {
  background-color: var(--overwatch-primary);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  border-radius: 0.5px;
}

.tooltip-popper :deep(.popper:hover),
.tooltip-popper :deep(.popper:hover > #arrow::before) {
  background-color: var(--overwatch-primary);
}

.tooltip-popper :deep(.popper ul) {
  list-style: none;
  color: var(--overwatch-neutral-500);
  margin: 0px;
  padding: 0;
}

.tooltip-popper :deep(.popper li) {
  position: relative;
  z-index: 10;
  padding: calc(2 * var(--spacing-base)) var(--spacing-m);
  user-select: none;
  cursor: pointer;
}

.tooltip-popper :deep(.popper li:hover) {
  background-color: color-mix(
    in srgb,
    var(--overwatch-accent),
    transparent 75%
  );
}

/* menu for popper */
.menu-popper :deep(.popper) {
  background-color: var(--overwatch-secondary);
  border-radius: 5px;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
  padding: 0px;
}

.menu-popper :deep(.popper #arrow::before) {
  background-color: var(--overwatch-secondary);
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
}

.menu-popper :deep(.popper:hover),
.menu-popper :deep(.popper:hover > #arrow::before) {
  background-color: var(--overwatch-secondary);
}

/* Use ul and li to create a nice menu */

.menu-popper :deep(.popper ul) {
  list-style: none;
  color: var(--overwatch-neutral-100);
  margin: 0px;
  padding: 0;
}

.menu-popper :deep(.popper li) {
  position: relative;
  z-index: 10;
  padding: calc(2 * var(--spacing-base)) var(--spacing-m);
  user-select: none;
  cursor: pointer;
}

.menu-popper :deep(.popper li:hover) {
  background-color: color-mix(
    in srgb,
    var(--overwatch-accent),
    transparent 75%
  );
}
</style>

<template>
  <div class="content">
    <search-header
      :button-title="getFilterButtonTitle()"
      @search-filter-change="setSearchFilter"
      :transparentBackground="true"
      :use-dropdown="true"
      searchTextPlaceHolder="Search"
      :keepButtonTitle="true"
    >
      <template v-slot:leftnav>
        <div class="title">
          <div style="display: flex; align-items: center;">
            Audit Log
          </div>
          <div class="eventCount">
            {{ totalEvents }}
          </div>
          <div class="refreshIcon" @click="loadAppEvents()">
            <RocIcon color="black" size="sm" icon="refresh" />
          </div>
        </div>
      </template>
      <template v-slot:filterWidgets>
        <div class="dateField">
          <RocSingleDatePicker
            v-model="startTime"
            :key="startTime"
            :placeholder="'Start Date'"
            clearButton
          />
          <RocSingleDatePicker
            v-model="endTime"
            :minDate="startTime"
            :key="endTime"
            :placeholder="'End Date'"
            clearButton
          />
        </div>
      </template>
      <template v-slot:dropdown-slot>
        <MDBDropdownItem
          v-for="filter in filters"
          :key="filter.value"
          href=""
          @click.prevent.stop="
            () => {
              filter.enabled = !filter.enabled;
            }
          "
        >
          <div class="d-flex flex-row">
            <RocCheckbox v-model="filter.enabled" @click.stop="() => {}" />
            <span style="margin-top: 2px; margin-left: var(--spacing-m);">{{
              filter.label
            }}</span>
          </div>
        </MDBDropdownItem>
      </template>
    </search-header>
    <div v-if="isLoading"><roc-spinner /></div>
    <div v-else class="eventListHolder">
      <div class="eventHeader">
        <div style="flex: 1;">Timestamp</div>
        <div style="flex: 1;">User</div>
        <div style="flex: 1;">Event Type</div>
        <div style="flex: 3;">Message</div>
      </div>
      <div class="eventList">
        <div v-for="appEvent in appEvents" :key="appEvent._id">
          <div class="eventCard">
            <div style="flex: 1;">
              {{ getDate(appEvent.timestamp) }}
            </div>
            <div style="flex: 1;">
              {{ appEvent.user }}
            </div>
            <div style="flex: 1;">
              {{ appEvent.event_type }}
            </div>
            <div style="flex: 3;">
              {{ appEvent.description }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <RocPageSelector
      class="stick-to-bottom"
      :currPage="currPage"
      :lastPage="lastPage"
      @goToPage="changePage"
    />
  </div>
</template>

<script setup>
import SearchHeader from "@/components/ui/SearchHeader";
import RocPageSelector from "@/components/ui/RocPageSelector.vue";
import RocCheckbox from "@/components/ui/RocCheckbox.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocSingleDatePicker from "@/components/ui/RocSingleDatePicker.vue";
import { ref, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
import { MDBDropdownItem } from "mdb-vue-ui-kit";
import { debounce } from "lodash";
import moment from "moment";
import dateHelper from "@/js/dateHelper";

const store = useStore();
const isLoading = ref(false);
const appEvents = ref([]);
const currPage = ref(1);
const lastPage = ref(1);
const totalEvents = ref(0);
const startTime = ref();
const endTime = ref();

const filters = reactive([
  { label: "System", value: "System", enabled: false },
  {
    label: "Authentication",
    value: "UserLogin,UserLogout,UserPasswordChange",
    enabled: false
  },
  { label: "Error", value: "Error", enabled: false },
  { label: "Warning", value: "Warning", enabled: false },
  { label: "Create/Update/Delete", value: "CRUD", enabled: false }
]);
const filterText = ref("");

onMounted(() => {
  loadAppEvents();
});

watch(
  [filters, filterText, startTime, endTime],
  debounce(() => {
    loadAppEvents();
  }, 1000)
);

function reset() {
  appEvents.value = [];
  totalEvents.value = 0;
  currPage.value = 1;
  lastPage.value = 1;
}

async function loadAppEvents() {
  isLoading.value = true;
  reset();
  const payload = {
    page: currPage.value,
    types: getTypes(),
    text: filterText.value,
    limit: 20
  };
  if (startTime.value) {
    payload.startTime = moment(startTime.value).startOf("day");
  }
  if (endTime.value) {
    payload.endTime = moment(endTime.value).endOf("day");
  }
  const eventData = await store.dispatch("settings/loadAppEvents", payload);
  isLoading.value = false;
  if (eventData?.status === "success") {
    totalEvents.value = eventData.result.totalEvents;
    lastPage.value = eventData.result.totalPages;
    if (eventData.result.events?.length) {
      appEvents.value.push(...eventData.result.events);
    }
  }
}

function getTypes() {
  let typeCsv = "";
  for (let filterEntry of filters) {
    if (filterEntry.enabled) {
      typeCsv += filterEntry.value;
      typeCsv += ",";
    }
  }
  return typeCsv;
}

function changePage(value) {
  currPage.value = value;
  loadAppEvents();
}

function setSearchFilter(text) {
  filterText.value = text;
}

function getTypeFilterCount() {
  const enabledTypes = filters.filter((type) => type.enabled);
  return enabledTypes.length;
}

function getFilterButtonTitle() {
  const typeCount = getTypeFilterCount();
  if (typeCount > 0) {
    return `Filter (${typeCount})`;
  } else {
    return `Filter`;
  }
}
const dateTool = new dateHelper();

function getDate(timestamp) {
  return dateTool.getDisplayDateTime(new Date(timestamp));
}
</script>

<style scoped lang="scss">
.eventListHolder {
  padding-left: var(--spacing-m);
  padding-right: var(--spacing-m);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}
.eventList {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: auto;
}
.content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.stick-to-bottom {
  flex-shrink: auto;
}
.title {
  gap: var(--spacing-xl);
  padding-left: 10px;
  display: flex;
}
.stick-to-top {
  position: sticky;
  top: 0;
  z-index: 50;
}
.eventHeader {
  @include overwatch-title-xsmall;
  width: 100%;
  justify-content: start;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
  box-shadow: var(--overwatch-header-box-shadow);
  background: var(--overwatch-secondary);
  padding: var(--spacing-s);
}

.eventCount {
  @include overwatch-body-med;
  display: flex;
  align-items: center;
  color: gray;
}
.refreshIcon {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.dateField {
  @include overwatch-body-small;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-m);
}

.eventCard {
  @include overwatch-body-med;
  width: 100%;
  justify-content: start;
  display: flex;
  flex-direction: row;
  gap: var(--spacing-m);
  border-bottom: solid 1px #abb4bd;
  padding: var(--spacing-s);
}

.eventCard:hover {
  background-color: var(--overwatch-neutral-400);
}

/* MOBILE */
@media (max-width: 480px) {
  .dateField {
    flex-direction: column;
  }
}
</style>

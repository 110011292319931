<script setup>
import { ref, computed, reactive, onMounted, watch } from "vue";
import RocIcon from "@/components/ui/RocIcon";
import RocInput from "@/components/ui/RocInput";
import MyPopperWrapper from "@/components/ui/MyPopperWrapper";
import RocTimezoneSingleSelect from "@/components/ui/RocTimezoneSingleSelect";
import DateHelper from "@/js/dateHelper";

import { DatePicker } from "v-calendar";

const props = defineProps({
  files: {
    type: Array,
    required: true,
    default: []
  },
  defaultTimezone: {
    type: String,
    required: false,
    default: ""
  }
});

const emit = defineEmits(["change-cameranames", "change-datetimes"]);

const dh = new DateHelper();

const fileRegexPatterns = ref({
  synology: "(.+)-(\\d{4})(\\d{2})(\\d{2})-(\\d{2})(\\d{2})(\\d{2})",
  genetec: "(.+)_(\\d+)-(\\d+)-(\\d+)_(\\d+)h(\\d+)min(\\d+)s(\\d+)ms",
  milestone: "(.+).video.(\\d{4})(\\d{2})(\\d{2})-(\\d{2}).(\\d{2}).(\\d{2})",
  spectrum:
    "(.+)_(\\d{4})_(\\d{2})_(\\d{2})_(\\d{1,2})([AaPp][Mm])_(\\d{2})_(\\d{2})"
});

const fileRegexTypesWithFiles = computed(() => {
  return Object.keys(fileRegexPatterns.value).filter(
    (key) => key in typeToFilenames && typeToFilenames[key].length > 0
  );
});

const fileRegexPatternOrder = ref({
  synology: {
    cameraname: 0,
    year: 1,
    month: 2,
    day: 3,
    hour: 4,
    min: 5,
    sec: 6,
    ms: null,
    ampm: null,
    timezone: null
  },
  genetec: {
    cameraname: 0,
    year: 1,
    month: 2,
    day: 3,
    hour: 4,
    min: 5,
    sec: 6,
    ms: 7,
    ampm: null,
    timezone: null
  },
  milestone: {
    cameraname: 0,
    year: 1,
    month: 2,
    day: 3,
    hour: 4,
    min: 5,
    sec: 6,
    ms: null,
    ampm: null,
    timezone: null
  },
  spectrum: {
    cameraname: 0,
    year: 1,
    month: 2,
    day: 3,
    hour: 4,
    min: 6,
    sec: 7,
    ms: null,
    ampm: 5,
    timezone: null
  }
});

const isProcessing = ref(false);

onMounted(() => {
  isProcessing.value = true;
  processFiles();
  isProcessing.value = false;
});

const typeToFilenames = reactive({}); // Need a mapping from regex types to the filenames
const filenameToType = reactive({});
const filenamesToParsed = reactive({}); // Need a mapping from filenames to the groups
const uncategorized = ref([]);

function processFiles() {
  for (let file of props.files) {
    const split = file.fileHandle.name.split(".");
    const filename = split.slice(0, split.length - 1).join("."); // Filename without extension. Assuming it ends in .xxx

    let matched = false;
    for (const [regexType, regexVal] of Object.entries(
      fileRegexPatterns.value
    )) {
      if (!typeToFilenames[regexType]) {
        typeToFilenames[regexType] = [];
      }

      let regex = new RegExp(regexVal);

      const result = filename.match(regex);

      const patternMatched = result && filename === result[0];
      if (patternMatched) {
        typeToFilenames[regexType].push(filename);
        filenameToType[filename] = regexType;
        filenamesToParsed[filename] = result;

        populateCameraname(filename);
        populateDatetime(filename);

        matched = true;
        break;
      } else {
        // Set timezones to default even if it's not match
        filenamesToTimezone[filename] = props.defaultTimezone;
      }
    }
    if (!matched) {
      uncategorized.value.push(filename);
    }
  }

  // Autoselect first uncategorized file.
  if (uncategorized.value.length > 0) {
    selectedFilename.value = uncategorized.value[0];
  }
}

const isOpenRefs = ref([]);
function toggleFolder(idx) {
  isOpenRefs.value[idx] = !isOpenRefs.value[idx];
}

const selectedFilename = ref("");

const filenamesToCameraname = reactive({});
const filenamesToDatetime = reactive({});

const filenamesToTimezone = reactive({}); // This one is for visual consistency.

function populateCameraname(filename) {
  filenamesToCameraname[filename] = filenamesToParsed[filename][1];
}
function populateDatetime(filename) {
  const parts = filenamesToParsed[filename].slice(1);

  let pattern = filenameToType[filename];

  let year = parts[fileRegexPatternOrder.value[pattern]["year"]];
  let month = parts[fileRegexPatternOrder.value[pattern]["month"]];
  let day = parts[fileRegexPatternOrder.value[pattern]["day"]];
  let hour = parts[fileRegexPatternOrder.value[pattern]["hour"]] || 0;
  let min = parts[fileRegexPatternOrder.value[pattern]["min"]] || 0;
  let sec = parts[fileRegexPatternOrder.value[pattern]["sec"]] || 0;
  let ms = parts[fileRegexPatternOrder.value[pattern]["ms"]] || 0;
  let ampm = parts[fileRegexPatternOrder.value[pattern]["ampm"]];

  let timezone = parts[fileRegexPatternOrder.value[pattern]["timezone"]];
  // TODO: Parse timezone

  // Convert AMPM to 24 hours
  if (ampm) {
    if ((ampm == "PM" || ampm == "pm") && hour < 12) {
      hour = parseInt(hour) + 12;
    }
  }

  let offset = "";
  filenamesToTimezone[filename] = props.defaultTimezone;
  const offsetString = dh.getUTCOffsetFromTimezoneName(props.defaultTimezone);
  offset = offsetString;

  const datestring = `${year}-${month}-${day}T${hour}:${min}:${sec}.${ms}${offset}`;

  const datetime = new Date(datestring);

  filenamesToDatetime[filename] = datetime;
}

// Move calendar to selected file's parsed datetime.
const calendar = ref();
watch(selectedFilename, async (nv) => {
  if (filenamesToDatetime[nv]) {
    await calendar.value.move(filenamesToDatetime[nv]);
  } else {
    await calendar.value.move(new Date());
  }
});

const isShowingCalendar = ref(false);

const computedDatetimeString = computed(() => {
  if (!selectedFilename.value) {
    return "";
  }
  if (!filenamesToDatetime[selectedFilename.value]) {
    return "No datetime set";
  }

  let output = filenamesToDatetime[selectedFilename.value].toLocaleString(
    "en-US",
    {
      timeZone: filenamesToTimezone[selectedFilename.value]
    }
  );
  let offset = new Intl.DateTimeFormat("en", {
    timeZone: filenamesToTimezone[selectedFilename.value],
    timeZoneName: "shortOffset"
  })
    .formatToParts()
    .find((part) => part.type === "timeZoneName").value;
  output += ` - (${offset}) ${filenamesToTimezone[selectedFilename.value]}`;
  return output;
});

function emitDetails() {
  emit("change-cameranames", filenamesToCameraname);
  emit("change-datetimes", filenamesToDatetime);
}

defineExpose({
  emitDetails
});
</script>

<template>
  <div style="height: 100%;">
    <roc-spinner v-if="isProcessing" />
    <div v-else style="height: 100%; display: flex; flex-direction: column;">
      <div class="uncategorized-warning" v-if="uncategorized.length > 0">
        <RocIcon icon="error" color="red" size="md" />
        <div class="overwatch-body-med">
          <div>
            There have been files which the wizard was unable to autoparse.
          </div>
          <div>
            Please make sure the files have the correct camera name and date.
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="files">
          <div v-if="uncategorized.length > 0">
            <div class="files-header">
              Unparsed files
            </div>
            <ul>
              <li
                v-for="filename of uncategorized"
                class="file-item"
                :class="{ selected: selectedFilename === filename }"
                @click="selectedFilename = filename"
              >
                <RocIcon
                  :icon="
                    filenamesToCameraname[filename]?.length > 0 &&
                    filenamesToDatetime[filename]
                      ? 'check'
                      : 'exit'
                  "
                  :color="
                    filenamesToCameraname[filename]?.length > 0 &&
                    filenamesToDatetime[filename]
                      ? 'primary'
                      : 'red'
                  "
                  size="xs"
                />
                {{ filename }}
              </li>
            </ul>
          </div>
          <div
            v-if="files.length > uncategorized.length"
            style="margin-top: var(--spacing-s);"
          >
            <div class="files-header">
              Parsed files
            </div>
            <ul class="outer-list">
              <li
                v-for="[idx, type] in fileRegexTypesWithFiles.entries()"
                :ref="() => isOpenRefs.push(false)"
              >
                <a @click="toggleFolder(idx)">
                  {{ type }}
                  <RocIcon size="xs" icon="downArrow" :flip="isOpenRefs[idx]" />
                </a>
                <ul v-show="isOpenRefs[idx]">
                  <li
                    v-for="filename of typeToFilenames[type]"
                    class="file-item"
                    :class="{ selected: selectedFilename === filename }"
                    @click="selectedFilename = filename"
                  >
                    <RocIcon
                      :icon="
                        filenamesToCameraname[filename]?.length > 0 &&
                        filenamesToDatetime[filename]
                          ? 'check'
                          : 'exit'
                      "
                      :color="
                        filenamesToCameraname[filename]?.length > 0 &&
                        filenamesToDatetime[filename]
                          ? 'primary'
                          : 'red'
                      "
                      size="xs"
                    />
                    {{ filename }}
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="settings">
          <div
            v-show="selectedFilename"
            style="overflow-wrap: anywhere; width: 100%;"
          >
            <div class="overwatch-title-small">
              {{ selectedFilename }}
            </div>
            <div class="setting-row">
              <div>Camera Name</div>
              <RocInput v-model="filenamesToCameraname[selectedFilename]" />
            </div>
            <div class="setting-row">
              <div>Datetime</div>
              <MyPopperWrapper style="width: 100%; margin: 0; border: 0;">
                <div
                  class="input-rectangle"
                  style="cursor: pointer; width: 100%;"
                  @click="isShowingCalendar = !isShowingCalendar"
                >
                  <RocIcon color="primary" size="sm" icon="calendar" />
                  <input
                    class="date-display-input"
                    placeholder="Add date and time"
                    :value="computedDatetimeString"
                    readonly
                  />
                </div>
                <template #content>
                  <div class="date-box">
                    <DatePicker
                      ref="calendar"
                      v-model="filenamesToDatetime[selectedFilename]"
                      :timezone="filenamesToTimezone[selectedFilename]"
                      mode="dateTime"
                      is24hr
                    />
                    <RocTimezoneSingleSelect
                      :currently-selected="
                        filenamesToTimezone[selectedFilename]
                      "
                      @selection-changed="
                        (v) => (filenamesToTimezone[selectedFilename] = v)
                      "
                    />
                  </div>
                </template>
              </MyPopperWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
ul {
  @include overwatch-body-small;
  margin: var(--spacing-base) 0;
  list-style: none;
  padding-left: 0;
}

ul.outer-list {
  @include overwatch-body-med;
  list-style-type: none;
  text-transform: capitalize;
  padding: 0;
}

a {
  display: flex;
  align-items: center;
  gap: var(--spacing-base);
  margin-top: var(--spacing-s);
  margin-left: var(--spacing-s);
}

li.file-item {
  display: block;
  padding: var(--spacing-base);
  text-wrap: wrap;
}

li.file-item:hover {
  background-color: var(--overwatch-neutral-400);
}

.selected {
  background-color: var(--overwatch-neutral-300);
}

.columns {
  flex: 1;
  margin-top: var(--spacing-s);
  gap: var(--spacing-base);
  min-height: 0;

  display: flex;
}

.files {
  @include overwatch-body-med;
  height: 100%;
  // padding: var(--spacing-s);
  background-color: var(--overwatch-neutral-500);
  border-radius: 3px;
  overflow-wrap: break-word;
  overflow: auto;

  scrollbar-width: thin;
  flex: 1;
}

.files-header {
  @include overwatch-title-small;
  padding: var(--spacing-m);
  padding-left: var(--spacing-s);
  padding-bottom: var(--spacing-base);
}

.settings {
  height: 100%;
  flex: 2;
}

.setting-row {
  margin-top: var(--spacing-s);
}

.datetime-text {
  @include overwatch-body-small;
}

.datetime-text:hover {
  color: var(--overwatch-primary);
}

.timezone-select {
  padding: var(--spacing-xs);
  flex: 1;
}

.date-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: var(--spacing-s);
  border-radius: 4px;
  padding: var(--spacing-s);
  background-color: var(--overwatch-neutral-400);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
}

.uncategorized-warning {
  display: flex;

  border-radius: 5px;
  border: solid 1px var(--overwatch-error);
  align-items: center;
  gap: var(--spacing-l);
  padding: var(--spacing-s) 0 var(--spacing-s) var(--spacing-l);
  // padding-left: var(--spacing-l);
}
.input-rectangle {
  height: 45px;
  border-radius: 5px;
  border: solid 1px var(--overwatch-neutral-300);
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);

  display: flex;
  align-items: center;
  padding: var(--spacing-s) var(--spacing-m) var(--spacing-s) var(--spacing-m);

  gap: 10px;
}
.date-display-input {
  @include overwatch-body-small;
  flex: 1;
  border: 0px;
  margin: 0 auto;
  text-align: left;
  color: var(--overwatch-neutral-100);
  background-color: var(--overwatch-neutral-500);
  cursor: pointer;
}
</style>

<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { debounce } from "lodash";
import RocInput from "@/components/ui/RocInput.vue";
import RocButton from "@/components/ui/RocButton.vue";
import RocTextArea from "@/components/ui/RocTextArea.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocGrid from "@/components/ui/RocGrid.vue";
import Popper from "vue3-popper";

const props = defineProps({
  refIndex: {
    type: Number
  },
  mode: {
    type: String
  },
  p_plateNumber: {
    type: String
  },
  p_fullname: {
    type: String
  },
  p_color: {
    type: String
  },
  p_make: {
    type: String
  },
  p_model: {
    type: String
  },
  p_year: {
    type: String
  },
  p_collapsed: {
    type: Boolean
  },
  p_notes: {
    type: String
  },
  vehicles: {
    type: Array
  },
  isEnrolling: {
    type: Boolean,
    default: false
  }
});
const store = useStore();
const emits = defineEmits(["handleChange"]);
const plateNumber = ref(props.p_plateNumber);
const fullname = ref(props.p_fullname);
const color = ref(props.p_color);
const make = ref(props.p_make);
const model = ref(props.p_model);
const year = ref(props.p_year);
const notes = ref(props.p_notes);
const collapsed = ref(props.p_collapsed);
const hasVehicles = ref(props.vehicles ? props.vehicles.splice(0) : []);
const AddingNewVehicle = ref(true);
const disableSaveButton = ref(true);
const bottomScroll = ref(null);
const gridEditDeleteMode = ref(true);

const colDefs = ref([
  { label: "Plate #", key: "plateNumber", editable: true },
  { label: "Full Name", key: "fullname", editable: true },
  { label: "Color", key: "color", editable: true },
  { label: "Make", key: "make", editable: true },
  { label: "Model", key: "model", editable: true },
  { label: "Year", key: "year", editable: true }
]);

function toJSON() {
  return {
    plateNumber: plateNumber.value,
    fullname: fullname.value,
    color: color.value,
    make: make.value,
    model: model.value,
    year: year.value,
    notes: notes.value
  };
}

const storeReset = computed(function() {
  if (store.getters["watchlists/noFiles"]) {
    hasVehicles.value.splice(0, hasVehicles.value.length);
    AddingNewVehicle.value = true;
  }
  if (hasVehicles.value.some((vehicle) => vehicle.status)) {
    colDefs.value.push({ label: "Status", key: "status" });
    gridEditDeleteMode.value = false;
  } else {
    colDefs.value = colDefs.value.filter((col) => {
      return col.label !== "Status";
    });
    gridEditDeleteMode.value = true;
  }

  return hasVehicles.value;
});

function plateAdded() {
  hasVehicles.value.push(toJSON());
  store.commit("watchlists/setNoFiles", false);
  AddingNewVehicle.value = false;

  // Clear values after adding
  plateNumber.value = "";
  fullname.value = "";
  color.value = "";
  make.value = "";
  model.value = "";
  year.value = "";
  notes.value = "";
}

function removeItem(index) {
  hasVehicles.value.splice(index, 1);
}

function removeAll() {
  hasVehicles.value.splice(0, hasVehicles.value.length);
}

function handleAddVehicle() {
  AddingNewVehicle.value = true;

  setTimeout(() => {
    bottomScroll.value.scrollIntoView({ behavior: "smooth" });
  }, 100);
}

function handleContentChange(e, index) {
  switch (e.target.id) {
    case "plateNumber":
      hasVehicles.value[index].plateNumber = e.target.innerText;
      break;
    case "fullname":
      hasVehicles.value[index].fullname = e.target.innerText;
      break;
    case "color":
      hasVehicles.value[index].color = e.target.innerText;
      break;
    case "make":
      hasVehicles.value[index].make = e.target.innerText;
      break;
    case "model":
      hasVehicles.value[index].model = e.target.innerText;
      break;
    case "year":
      hasVehicles.value[index].year = e.target.innerText;
      break;
    default:
      console.log("Value cant be edited on this vehicle");
  }
}

const isEnrollEncounter = computed(() => {
  return props.mode === "enrollEncounter";
});

watch(
  hasVehicles,
  (newVehicles) => {
    emits("handleChange", newVehicles);
  },
  { deep: true }
);

// watch changes to data, debounced (to avoid too many emits while typing)
// this is only used when enrolling a plate from an encounter, which updates the data in place
watch(
  [plateNumber, fullname, color, make, model, year, notes],
  debounce(() => {
    if (isEnrollEncounter.value) {
      emits("handleChange", toJSON());
    }
  }, 500)
);

watch(plateNumber, () => {
  disableSaveButton.value = !(plateNumber.value.length > 0);
});
</script>

<template>
  <div
    class="d-flex flex-column vehicleCrud"
    :class="{ border: !isEnrollEncounter }"
  >
    <div
      v-if="!isEnrollEncounter"
      class="d-flex align-items-center"
      style="gap: var(--spacing-m); margin-bottom: var(--spacing-m);"
    >
      Vehicles
      <RocButton
        class="d-flex justify-content-end"
        :type="'primary'"
        @click="handleAddVehicle"
        :disabled="AddingNewVehicle || isEnrolling"
      >
        New Vehicle
      </RocButton>
    </div>
    <div v-if="storeReset?.length > 0">
      <RocGrid
        :columnDefs="colDefs"
        :rows="storeReset"
        :allowDelete="gridEditDeleteMode"
        :allowEdit="gridEditDeleteMode"
        @deleteAll="removeAll"
        @deleteItem="removeItem"
        @contentChanged="handleContentChange"
      >
        <template #status="{value,error}">
          <div class="statusMessage">
            <Popper
              v-if="value === 'success'"
              class="rocPopper"
              arrow
              content="Enrollment successful."
            >
              <RocIcon icon="check" size="lg" color="primary" />
            </Popper>
            <Popper
              v-else-if="value === 'failed'"
              class="rocPopper"
              arrow
              :content="error"
            >
              <RocIcon icon="error" color="red" size="md" />
            </Popper>
            <roc-spinner v-else :size="'sm'" />
          </div>
        </template>
      </RocGrid>
    </div>
    <div id="textFieldGroup" v-if="AddingNewVehicle">
      <div class="fieldGroup">
        <div
          class="overwatch-title-xxsmall"
          style="margin-bottom: var(--spacing-m);"
        >
          <span>Plate Number</span>
          <RocInput
            v-model="plateNumber"
            :placeholder="'Plate Number'"
            style="background: var(--overwatch-neutral-500);"
            type="text"
          />
        </div>
        <div
          class="overwatch-title-xxsmall"
          style="margin-bottom: var(--spacing-m);"
        >
          <span>Full Name</span>
          <RocInput
            v-model="fullname"
            :placeholder="'Name of the vehicle'"
            style="background: var(--overwatch-neutral-500);"
            type="text"
          />
        </div>
        <div
          class="overwatch-title-xxsmall"
          style="margin-bottom: var(--spacing-m);"
        >
          <span>Color</span>
          <RocInput
            v-model="color"
            :placeholder="'Color of the vehicle'"
            style="background: var(--overwatch-neutral-500);"
            type="text"
          />
        </div>
        <div v-if="!isEnrollEncounter">
          <div v-if="collapsed">
            <RocIcon
              icon="downArrow"
              size="sm"
              style="margin-left: 5px;"
              @click="collapsed = false"
            ></RocIcon>
          </div>
          <div v-else>
            <RocIcon
              icon="downArrow"
              size="sm"
              style="margin-left: 5px; transform: rotate(180deg)"
              @click="collapsed = true"
            ></RocIcon>
          </div>
        </div>
      </div>
      <div v-if="!collapsed || isEnrollEncounter" class="fieldGroup">
        <div
          class="overwatch-title-xxsmall"
          style="margin-bottom: var(--spacing-m);"
        >
          <span>Make</span>
          <RocInput
            v-model="make"
            :placeholder="'Make of the vehicle'"
            style="background: var(--overwatch-neutral-500);"
            type="text"
          />
        </div>
        <div
          class="overwatch-title-xxsmall"
          style="margin-bottom: var(--spacing-m);"
        >
          <span>Model</span>
          <RocInput
            v-model="model"
            :placeholder="'Model of the vehicle'"
            style="background: var(--overwatch-neutral-500);"
            type="text"
          />
        </div>
        <div
          class="overwatch-title-xxsmall"
          style="margin-bottom: var(--spacing-m);"
        >
          <span>Year</span>
          <RocInput
            v-model="year"
            :placeholder="'Year of the vehicle'"
            style="background: var(--overwatch-neutral-500);"
            type="text"
          />
        </div>
      </div>
      <div
        v-if="!collapsed"
        class="d-flex justify-content-start"
        style="width: 100%; margin-top: 10px; gap: 10px"
      >
        <div class="overwatch-title-xxsmall" style=" width: 100%">
          <p>Notes</p>
          <RocTextArea
            v-model="notes"
            rows="3"
            :placeholder="'Add note for this vehicle'"
            style="background: var(--overwatch-neutral-500); resize: none;"
          />
        </div>
      </div>
    </div>
    <div
      v-if="!isEnrollEncounter"
      class="d-flex justify-content-end"
      style="gap: var(--spacing-m)"
    >
      <RocButton
        class="d-flex justify-content-end"
        style="margin-top: 10px;"
        :type="'primary'"
        :disabled="disableSaveButton"
        @click="plateAdded"
      >
        Save Changes
      </RocButton>
    </div>
    <div ref="bottomScroll"></div>
  </div>
</template>

<style scoped lang="scss">
.fieldGroup {
  display: flex;
  justify-content: start;
  width: 100%;
  margin-top: 10px;
  gap: 10px;
}

/* Solid border */
hr.solid {
  border-top: 3px solid var(--overwatch-neutral-400);
}

.border {
  border: solid 1px var(--overwatch-neutral-300);
}

.vehicleCrud {
  padding: 15px;
  margin-bottom: 10px;
  max-height: 400px;
  overflow: auto;
}

.statusMessage {
  @include overwatch-body-xsmall;
}

.rocPopper :deep(.popper) {
  background: var(--overwatch-primary);
  padding: 20px;
  border-radius: 20px;
  color: var(--overwatch-button-text);
  text-transform: uppercase;
}

.rocPopper :deep(.popper #arrow::before) {
  background: var(--overwatch-primary);
}

.rocPopper :deep(.popper:hover),
.rocPopper :deep(.popper:hover > #arrow::before) {
  background: var(--overwatch-primary);
}

.disable-select {
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

@media (max-width: 480px) {
  .fieldGroup {
    display: flow;
  }
}
</style>

export default {
  encounters(state) {
    return state.encounters;
  },
  hasEncounters(state) {
    return state.encounters && state.encounters.length > 0;
  },
  shouldUpdate() {
    return true;
  },
  encounterFilterState(state) {
    return state.encounterFilterState;
  },
  searchImageData(state) {
    return state.searchImageData;
  },
  searchTemplateId(state) {
    return state.searchTemplateId;
  },
  searchModality(state) {
    return state.searchModality;
  },
  showImageSearchBox(state) {
    return state.searchTemplateId;
  },
  getSearchText(state) {
    return state.searchText;
  },
  cursor(state) {
    return state.cursor;
  },
  hasMoreData(state) {
    return state.moreData;
  },
  getDTFilter(state) {
    return state.dtFilterRange;
  },
  liveFeedCameras(state) {
    return state.liveFeedCameras;
  },
  cameraLiveFeedState(state) {
    return state.cameraLiveFeedState;
  },
  cameraLiveFeedMode(state) {
    return state.cameraLiveFeedMode;
  },
  matchesOnly(state) {
    return state.matchesOnly;
  },
  adjudicationFilter(state) {
    return state.adjudicationFilter;
  },
  attributesFilter(state) {
    return state.attributesFilter;
  },
  getAllFaceAttributes(state) {
    return state.allFaceAttributes;
  },
  getAllObjectAttributes(state) {
    return state.allObjectAttributes;
  },
  analyticsFilter(state) {
    return state.analyticsFilter;
  },
  liveFeedEnabled(state) {
    return state.liveFeedEnabled;
  },
  maxLiveEncounters(state) {
    return state.maxLiveEncounters;
  },
  queryPageSize(state) {
    return state.queryPageSize;
  },
  warningToast(state){
    return state.showWarningToast;
  },
  getDetectionAudioAlerts(state) {
    return state.detectionAudioAlerts;
  },
  getToastModalities(state) {
    return state.toastModalities;
  },
  getToastTimeout(state){
    return state.toastTimeout;
  },
  getAudioToPlay(state){
    return state.audioToPlay;
  }
};

export default {
  cases(state) {
    return state.cases;
  },
  cameras(state) {
    return state.cameras;
  },
  importerVisible(state) {
    return state.importerVisible;
  },
  editingCase(state) {
    return state.editingCase;
  },
  isCaseProcessing(state) {
    return state.isCaseProcessing;
  },
  getDateTimeParser(state) {
    return state.dateTimeParserValue;
  },
  activeMission(state) {
    return state.cases.find((c) => c._id === state.activeMissionId);
  },
  previousMission(state) {
    return state.cases.find((c) => c._id === state.previousMissionId);
  },
  activeMissionId(state) {
    return state.activeMissionId;
  },
  previousMissionId(state) {
    return state.previousMissionId;
  },
  selectedTable(state) {
    return state.selectedTable;
  },
  loadedOnce(state) {
    return state.loadedOnce;
  }
};

<script setup>
import { ref, computed, onMounted, onUnmounted, watch } from "vue";
import { useStore } from "vuex";
import ClusterEncountersGrid from "@/components/clusters/ClusterEncountersGrid.vue";
import ClusterEncounterVideo from "@/components/clusters/ClusterEncounterVideo.vue";
import filterMultiSelect from "@/components/ui/filterMultiSelect.vue";
import moment from "moment";
import RocButton from "@/components/ui/RocButton.vue";
import RocIcon from "@/components/ui/RocIcon.vue";
import RocPopper from "@/components/ui/RocPopper.vue";
import RocSelect from "@/components/ui/RocSelect.vue";
import ClusterGrid from "@/components/clusters/ClusterGrid.vue";
import RocCalendar from "@/components/ui/RocCalendar.vue";
import RocSpinner from "@/components/ui/RocSpinner.vue";
import BaseDialog from "@/components/ui/BaseDialog";
import { DateTime } from "luxon";

const store = useStore();

const props = defineProps({
  caseId: {
    type: String
  },
  clusterId: {
    type: String,
    required: false
  }
});

const dates = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "27",
  "28",
  "29",
  "30",
  "31"
];
const days = ["Mon", "Tues", "Wed", "Thur", "Fri", "Sat", "Sun"];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec"
];
const hours = [
  "00",
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "20",
  "21",
  "22",
  "23",
  "24"
];

const isVideoPopupVisible = computed(
  () => store.getters["clusters/isVideoPopupVisible"]
);
const isVideoDialogVisible = ref(false);
const globalVideoPlaybackEncounter = computed(
  () => store.getters["clusters/videoPlaybackEncounter"]
);

const isLoading = ref(false);
const isLoadingGrid = ref(false);

const timezone = ref();

const currentScale = ref("Months");
const showTooltip = ref(false);

const showTooltipZero = ref(false);
const showTooltipFive = ref(false);
const showTooltipFifteen = ref(false);
const showTooltipThirty = ref(false);

const blockSelected = ref(false);

const isCameraFilterOpen = ref(false);

const selectedCameraFilters = computed(() => {
  return store.getters["clusters/cameraFilters"];
});
const availableCameras = ref([]);

const dateRange = computed(() => {
  return store.getters["clusters/dtRangeFilter"];
});

const enrollObject = ref();
const isEnrollDialogVisible = ref();

const associatesTargetClusterThumbnail = computed(() => {
  if (associatesTargetCluster.value) {
    const modality = associatesTargetCluster.value.modality;
    if (modality && modality === "face") {
      const faceTemplateId =
        associatesTargetCluster.value.referenceEncounter.facetemplateId;
      return `/rest/v1/image/face/${faceTemplateId}/tn`;
    } else {
      const objectTemplateId =
        associatesTargetCluster.value.referenceEncounter.objtemplateId;
      return `/rest/v1/image/object/${objectTemplateId}/tn`;
    }
  } else {
    return "";
  }
});

const associatesTargetCluster = ref();

const displayGrid = ref(null);
const gridItemHeight = ref("50px");

const dateOne = ref();
const dateTwo = ref();
const dateDiff = ref(0);
const gridHeight = ref(7);
const gridWidth = ref(0);
const yearWidth = ref(32);
const currentIndex = ref(null);

async function getAvailableCameras() {
  let success = false;
  let retries = 0;

  while (!success && retries < 3) {
    let response = await store.dispatch("cases/getCamerasByCaseId", {
      caseId: props.caseId
    });
    if (response && response.status === "success") {
      availableCameras.value = response.result;
      availableCameras.value.forEach((c) => {
        c.value = c.GUID;
        c.label = c.name;
      });
      success = true;
    } else {
      retries++;
    }
  }
}

async function updateSelectedCameras(selectedCams) {
  isLoading.value = true;
  isLoadingGrid.value = true;
  store.commit("clusters/setCameraFilters", selectedCams.value);

  await getAssociateCluster();
  await loadClusters();
}

function getPolColor(count) {
  if (count >= 30) {
    return "var(--overwatch-primary)";
  }
  if (count >= 15) {
    return `rgb(from var(--overwatch-primary) r g b / 70%)`;
  }
  if (count >= 10) {
    return `rgb(from var(--overwatch-primary) r g b / 40%)`;
  }
  if (count >= 5) {
    return `rgb(from var(--overwatch-primary) r g b / 15%)`;
  }
  return "var(--overwatch-neutral-500)";
}

function enrollFace(eo) {
  enrollObject.value = eo;
  isEnrollDialogVisible.value = true;
}

function handleScaleChange(val) {
  blockSelected.value = false;
  currentIndex.value = null;
  currentScale.value = val;
  binDates(val);
}

function binDates(val) {
  const times = associatesTargetCluster.value?.encounters;
  switch (val) {
    case "Days":
      binDays(times);
      break;
    case "Months":
      binMonths(times);
      break;
    case "Years":
      binYears(times);
      break;
  }
  isLoadingGrid.value = false;
}

async function loadClusters() {
  store.commit("clusters/setClusters", []);
  await store.dispatch("clusters/loadClusters", props.caseId);
}

async function clearPolFilter() {
  blockSelected.value = false;
  currentIndex.value = null;
  store.commit("clusters/setPolFilter", null);
  await loadClusters();
}

async function polFilter(index, count) {
  if (count === 0) {
    return;
  }
  if (currentIndex.value === index) {
    clearPolFilter();
    return;
  }
  blockSelected.value = true;
  currentIndex.value = index;
  const width = gridWidth.value;

  const row = Math.floor(index / width);
  const column = index % width;

  const yearOffset = Math.floor(column / yearWidth.value);
  const year = dateOne.value + yearOffset;
  const polFilter = { timezone: timezone.value };
  if (currentScale.value === "Months") {
    const dateValue =
      ((column % yearWidth.value) + yearWidth.value) % yearWidth.value;
    polFilter.month = row + 1;
    polFilter.dayOfMonth = dateValue + 1;
    polFilter.year = year;
  } else if (currentScale.value === "Years") {
    const monthVal =
      ((column % yearWidth.value) + yearWidth.value) % yearWidth.value;
    polFilter.month = monthVal + 1;
    polFilter.dayOfWeek = row + 1;
    polFilter.year = year;
  } else {
    const hourVal =
      ((column % yearWidth.value) + yearWidth.value) % yearWidth.value;
    polFilter.dayOfWeek = row + 1;
    polFilter.hour = hourVal;
    polFilter.month = yearOffset + 1;
  }

  store.commit("clusters/setPolFilter", polFilter);
  await getAssociateCluster();
  await loadClusters();
}

function getDateDiff(dates) {
  dates?.sort();
  if (!dates || dates.length === 0) return { diff: 1, d1: 1970, d2: 1970 };
  const d1 = moment(new Date(dates[0].timestamp));
  const d2 = moment(new Date(dates[dates.length - 1].timestamp));
  let diff = Math.ceil(d2.diff(d1, "years", true));
  diff = diff > 0 ? diff : 1;
  dateOne.value = d1.year();
  dateTwo.value = d2.year();
  dateDiff.value = diff;
  return { diff, d1: d1.year(), d2: d2.year() };
}

function binDays(times) {
  const height = 7;
  const width = 25 * 12;
  const grid = new Array(height * width).fill(0, 0, height * width);
  yearWidth.value = 25;
  gridWidth.value = width;
  gridHeight.value = height;

  for (const time of times) {
    const d = DateTime.fromMillis(time?.timestamp, { zone: timezone.value });
    const offset = d.month - 1;
    const row = d.weekday - 1;
    const column = d.hour + yearWidth.value * offset;
    const index = row * width + column;
    grid[index] += 1;
  }
  displayGrid.value = grid;
}

function binYears(times) {
  const { diff, d1, d2 } = getDateDiff(times);
  const width = 12 * diff;
  const height = 7;
  yearWidth.value = 12;
  gridWidth.value = width;
  gridHeight.value = height;

  const grid = new Array(height * width).fill(0, 0, height * width);

  if (!times || times.length === 0) return;
  for (const time of times) {
    const d = DateTime.fromMillis(time?.timestamp, { zone: timezone.value });
    const yearOffset = Math.abs(d.year - d1);
    const row = d.weekday - 1;
    const column = d.month - 1 + yearWidth.value * yearOffset;
    const index = row * width + column;
    grid[index] += 1;
  }
  displayGrid.value = grid;
}

function binMonths(times) {
  const { diff, d1, d2 } = getDateDiff(times);
  const width = 32 * diff;
  const height = 12;
  yearWidth.value = 32;
  gridWidth.value = width;
  gridHeight.value = height;

  const grid = new Array(height * width).fill(0, 0, height * width);

  if (!times || times.length === 0) return;
  for (const time of times) {
    const d = new Date(time?.timestamp);
    const yearOffset = Math.abs(d.getFullYear() - d1);
    const row = d.getMonth();
    const column = d.getDate() - 1 + yearWidth.value * yearOffset;
    const index = row * width + column;
    grid[index] += 1;
  }
  displayGrid.value = grid;
}

function buttonTextColor(condition) {
  if (condition) {
    return "color: var(--overwatch-button-text);";
  } else {
    return "color: var(--overwatch-neutral-100);";
  }
}

async function handleTimeFilterChange(filter) {
  isLoading.value = true;
  isLoadingGrid.value = true;
  store.commit("clusters/setAssociatesTimeRangeFilter", filter);
  await getAssociateCluster();
  await loadClusters();
}
async function removeTimeFilterRange() {
  isLoading.value = true;
  isLoadingGrid.value = true;
  store.commit("clusters/setAssociatesTimeRangeFilter", []);
  await getAssociateCluster();
  await loadClusters();
}

async function clearFilters() {
  isLoading.value = true;
  isLoadingGrid.value = true;
  store.commit("clusters/setAttributeFilters", {});
  store.commit("clusters/setCameraFilters", []);
  store.commit("clusters/setWatchlistMatchFilter", false);
  store.commit("clusters/setAnalyticsFilters", []);

  await getAssociateCluster();
  await loadClusters();
}

async function getAssociateCluster() {
  const res = await store.dispatch("clusters/getClusterById", props.clusterId);
  associatesTargetCluster.value = res.result;
  store.commit("clusters/setAssociatesTargetCluster", res.result);
  isLoading.value = false;
}

function convertTimeString(time) {
  const hours = Number(time);

  // calculate
  let timeValue;

  if (hours > 0 && hours <= 12) {
    timeValue = "" + hours;
  } else if (hours > 12) {
    timeValue = "" + (hours - 12);
  } else if (hours == 0) {
    timeValue = "12";
  }
  timeValue += hours >= 12 ? " P.M." : " A.M."; // get AM/PM

  return timeValue;
}

watch(associatesTargetCluster, () => {
  binDates(currentScale.value);
});

onMounted(async () => {
  isLoading.value = true;
  isLoadingGrid.value = true;

  const mission = store.getters["clusters/mission"];
  if (!mission) {
    const res = await store.dispatch("cases/getCaseById", props.caseId);
    if (res.status === "success")
      store.commit("clusters/setMission", res.result);
  }
  timezone.value = store.getters["clusters/mission"]?.timezone;

  getAvailableCameras();
  getAssociateCluster();
  //scale grid sizes
  // day: 7 days x 24 hours
  // months: 12 months x 31 days (repeat)
  // years: 7 days x 12 months (repeat)
});

onUnmounted(() => {
  store.commit("clusters/setVideoPlaybackEncounter", null);
  store.commit("clusters/setAssociatesTargetCluster", null);
  store.commit("clusters/setAssociatesSameFrameFilter", []);
  store.commit("clusters/setAssociatesTimeRangeFilter", []);
  store.commit("clusters/setPolFilter", null);
});
</script>

<template>
  <div>
    <div class="all-filters">
      <RocCalendar
        clusterPageMode
        @time-filter-change="handleTimeFilterChange"
        @clear-date-selection="removeTimeFilterRange"
        :dateValue="dateRange"
      />

      <RocPopper
        popperType="menu"
        @open:popper="isCameraFilterOpen = true"
        @close:popper="isCameraFilterOpen = false"
      >
        <RocButton
          class="filter-button"
          :type="selectedCameraFilters.length > 0 ? 'primary' : 'white'"
          style="cursor: default; height: 48px;"
        >
          <div class="d-flex align-items-center">
            <span :style="buttonTextColor(selectedCameraFilters.length > 0)"
              >Cameras</span
            >
            <div
              v-show="selectedCameraFilters.length > 0"
              class="filter-count-badge"
            >
              {{ selectedCameraFilters.length }}
            </div>
            <RocIcon
              v-if="selectedCameraFilters.length > 0"
              class="sort-text"
              color="white"
              size="xs"
              icon="exit"
              style="cursor: pointer;"
              @click.stop="clearFilters"
            />
          </div>
        </RocButton>
        <template #content>
          <div class="select-wrapper">
            <filterMultiSelect
              encounterTagsMode
              class="select"
              mode="tags"
              placeholder-text="Select cameras"
              :available-items="availableCameras"
              @selection-changed="updateSelectedCameras"
              :currently-selected="selectedCameraFilters"
            />
          </div>
        </template>
      </RocPopper>

      <div class="input-rectangle"></div>
    </div>
    <div class="target-section">
      <div v-if="!isLoading">
        <div class="overwatch-title-med">
          Target
          <span v-if="associatesTargetCluster" class="overwatch-title-small"
            >{{ associatesTargetCluster?.encounters?.length }} Encounters</span
          >
        </div>
        <div class="associate-list" v-if="associatesTargetCluster">
          <auth-img
            :src="associatesTargetClusterThumbnail"
            class="best-thumbnail"
            draggable="false"
          />
          <ClusterEncountersGrid
            associatesViewMode
            :encounters="associatesTargetCluster?.encounters"
            @enroll-face="enrollFace"
          />
        </div>
        <div v-else>
          No encounters available.
        </div>
      </div>
      <div v-else>
        <RocSpinner />
      </div>
    </div>
    <div class="target-section">
      <div v-if="!isLoadingGrid">
        <div class="pol-top-bar">
          <div class="left">
            <span class="overwatch-title-med">Target Pattern of Life</span>
            <span v-if="blockSelected" class="pill overwatch-body-small">
              <RocIcon icon="check" size="sm" />
              Block Selected
            </span>
            <span
              v-if="blockSelected"
              @click="clearPolFilter"
              style="cursor: pointer;"
              class="clear overwatch-body-small"
              >Clear</span
            >
          </div>
          <div class="right">
            <label>Scale</label>
            <RocSelect
              :availableOptions="['Days', 'Months', 'Years']"
              placeholder="Scale"
              :optionLabel="'value'"
              :currentlySelected="currentScale"
              @selectionChanged="handleScaleChange"
              style="width: 50%;"
            />
            <RocPopper
              hover
              arrow
              placement="top"
              popper-type="tooltip"
              v-model="showTooltip"
            >
              <RocIcon icon="tooltip" size="sm" />
              <template #content>
                The time period from which encounters have occurred, from a high
                level view to a lower level view
              </template>
            </RocPopper>
          </div>
        </div>
        <div class="pol-container">
          <div class="dates-side">
            <div class="date-side"></div>
            <div
              class="date-side"
              v-if="currentScale === 'Days' || currentScale === 'Years'"
              v-for="day in days"
            >
              {{ day }}
            </div>
            <div
              class="date-side"
              v-if="currentScale === 'Months'"
              v-for="month in months"
            >
              {{ month }}
            </div>
          </div>

          <div
            class="pol"
            v-if="displayGrid"
            :style="{
              gridTemplateColumns: `repeat(${gridWidth}, ${gridItemHeight})`,
              gridAutoColumns: gridItemHeight,
              gridTemplateRows: `repeat(${gridHeight + 1}, ${gridItemHeight})`
            }"
          >
            <div
              class="date-top"
              v-if="currentScale === 'Years'"
              v-for="(_, x) in gridWidth"
            >
              <div v-if="x % yearWidth === 0">
                {{ dateOne + x / yearWidth }}
              </div>
              {{
                months[((x % months.length) + months.length) % months.length]
              }}
            </div>
            <div
              class="date-top"
              v-if="currentScale === 'Months'"
              v-for="(_, x) in gridWidth"
            >
              <div v-if="x % yearWidth === 0">
                {{ dateOne + x / yearWidth }}
              </div>
              {{ dates[((x % dates.length) + dates.length) % dates.length] }}
            </div>
            <div
              class="date-top"
              v-if="currentScale === 'Days'"
              v-for="(_, x) in gridWidth"
            >
              <div
                v-if="((x % hours.length) + hours.length) % hours.length === 0"
              >
                {{ months[x / hours.length] }}
              </div>
              {{
                convertTimeString(
                  hours[((x % hours.length) + hours.length) % hours.length]
                )
              }}
            </div>
            <div
              class="pol-item"
              @click="() => polFilter(index, count)"
              v-for="(count, index) in displayGrid"
              :key="`pol-item-${index}`"
              :style="{
                backgroundColor: `rgb(from var(--overwatch-primary) r g b / ${Math.log10(
                  count
                ) * 100}%)`,
                cursor: count > 0 ? 'pointer' : 'auto'
              }"
            >
              <RocIcon v-if="index === currentIndex" icon="check" size="md" />
            </div>
          </div>
        </div>

        <div class="pol-legend">
          <span class="label">None</span>

          <RocPopper
            hover
            arrow
            placement="top"
            popper-type="tooltip"
            v-model="showTooltipZero"
          >
            <div
              class="rect"
              :style="{ backgroundColor: getPolColor(0) }"
            ></div>
            <template #content>
              0 Encounters
            </template>
          </RocPopper>

          <RocPopper
            hover
            arrow
            placement="top"
            popper-type="tooltip"
            v-model="showTooltipFive"
          >
            <div
              class="rect"
              :style="{ backgroundColor: getPolColor(5) }"
            ></div>
            <template #content>
              5+ Encounters
            </template>
          </RocPopper>

          <RocPopper
            hover
            arrow
            placement="top"
            popper-type="tooltip"
            v-model="showTooltipFifteen"
          >
            <div
              class="rect"
              :style="{ backgroundColor: getPolColor(15) }"
            ></div>
            <template #content>
              15+ Encounters
            </template>
          </RocPopper>

          <RocPopper
            hover
            arrow
            placement="top"
            popper-type="tooltip"
            v-model="showTooltipThirty"
          >
            <div
              class="rect"
              :style="{ backgroundColor: getPolColor(30) }"
            ></div>
            <template #content>
              30+ Encounters
            </template>
          </RocPopper>

          <span class="label" style="margin-left: 4px">30+</span>
        </div>
      </div>
      <div v-else>
        <RocSpinner />
      </div>
    </div>

    <div class="associate-grid">
      <div class="target-section">
        <ClusterGrid :caseId="props.caseId" :isPolFilter="blockSelected" />
      </div>

      <div
        class="video-playback-box target-section"
        v-show="!isVideoPopupVisible"
      >
        <ClusterEncounterVideo
          v-if="globalVideoPlaybackEncounter"
          :caseId="props.caseId"
          @show-dialog="isVideoDialogVisible = true"
        />
        <div style="text-align: center;" v-else>
          Select an encounter to view context video.
        </div>
      </div>
    </div>

    <BaseDialog
      :show="isVideoDialogVisible"
      @close="isVideoDialogVisible = false"
    >
      <ClusterEncounterVideo :caseId="props.caseId" dialogMode />
    </BaseDialog>
  </div>
</template>

<style scoped lang="scss">
.associate-grid {
  display: grid;
  grid-template-columns: 3fr 1fr;
}

.video-playback-box {
  min-width: 0;
}

.filter-bar {
  display: flex;
  justify-content: space-between;
}

.all-filters {
  margin: var(--spacing-m) var(--spacing-xl);
  display: flex;
  justify-content: end;
  gap: var(--spacing-m);
}

.target-section {
  border-radius: 5px;
  border: solid 1px var(--overwatch-accent);
  background-color: var(--overwatch-secondary);
  margin: var(--spacing-m) var(--spacing-s) var(--spacing-m) 0;
  padding: var(--spacing-m);
}

.pol {
  --item-height: 50px;
  display: grid;
  grid-area: pol;
  overflow-x: auto;
  grid-auto-flow: row;
  gap: var(--spacing-base);
}

.pol-item {
  position: relative;
  display: grid;
  place-items: center;
  height: var(--item-height);
  background-color: var(--overwatch-secondary);
  border-radius: 1px;
  border: 1px solid var(--overwatch-neutral-400);
}

.pol-item-active {
  background-color: var(--overwatch-success);
}

.associate-list {
  display: grid;
  grid-template-columns: 1fr 4fr;
}

.pol-top-bar {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: var(--spacing-xl);

  div {
    display: flex;
    gap: var(--spacing-m);
    align-items: center;
  }

  .right {
    justify-content: end;
  }
}

.pol-container {
  display: flex;
}

.pol-legend {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: var(--spacing-s);
  align-items: center;

  .rect {
    width: 20px;
    height: 20px;
    margin: 0 0 0 4px;
    border: 1px solid var(--overwatch-neutral-400);
  }
}

.dates-top {
  grid-area: dates-top;
  display: grid;
  grid-template-columns: repeat(auto-fill, 50px);
  grid-auto-columns: 50px;
  grid-auto-flow: column;
  border-bottom: 1px solid var(--overwatch-neutral-100);
  margin-bottom: var(--spacing-xs);
  overflow-x: auto;
}

.date-top {
  height: 50px;
  width: 50px;
  border-bottom: 1px solid var(--overwatch-neutral-100);
  border-right: 1px solid var(--overwatch-neutral-100);
  text-align: center;
  display: grid;
  place-items: center;
  font-size: 0.8rem;
}

.dates-side {
  grid-area: dates-side;
  display: flex;
  flex-direction: column;
}

.date-side {
  width: 50px;
  height: 50px;
  margin: 0 var(--spacing-base) var(--spacing-base) 0;
  padding-block: var(--spacing-s);
}

.pill {
  border-radius: 50px;
  background-color: rgb(from var(--overwatch-success) r g b / 20%);
  padding: 4px 10px 4px 11px;
}

.clear {
  text-decoration: underline;
  color: var(--overwatch-button-primary);
}

.select-wrapper {
  width: 300px;
}
</style>

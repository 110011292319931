<template>
  <div>
    <div v-if="isLoading">
      <roc-spinner />
    </div>
    <div>
      <div
        class="user"
        style="margin-right: 8px; margin-bottom: 4px;font-size: 16px;"
      >
        Email
      </div>
      <input
        v-model="email"
        class="inputEmail overwatch-body-small"
        style="padding: 12px; opacity: .5"
        size="56"
        placeholder="Email Address"
        type="text"
        disabled
      />
    </div>

    <form>
      <PasswordInputs
        isEditMode
        :isSubmitting="isSubmitting"
        @verify="getVerification"
        :status="status"
      />
    </form>
    <div style="display:flex; justify-content: flex-end;">
      <RocButton @click="pressSave" @keydown.enter="pressSave"
        >Change Password
      </RocButton>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useStore } from "vuex";
import PasswordInputs from "@/components/settings/PasswordInputs";
import RocButton from "@/components/ui/RocButton";
import RocInput from "@/components/ui/RocInput";

export default {
  name: "retentionSettings",
  components: {
    PasswordInputs,
    RocButton,
    RocInput
  },
  emits: ["close"],

  setup(props, context) {
    const isLoading = ref(false);
    const store = useStore();
    const email = ref(store.getters["auth/email"]);
    const currentPassword = ref("");
    const currentPasswordIcon = ref("eye-slash");
    const currentPasswordInputType = ref("password");

    const newPassword = ref("");
    const newPasswordIcon = ref("eye-slash");
    const newPasswordInputType = ref("password");

    const isSubmitting = ref(false);

    function pressSave() {
      isSubmitting.value = true;
    }

    const verificationResult = ref(null);

    function getVerification(payload) {
      verificationResult.value = payload;
    }

    watch(verificationResult, (newValue, oldValue) => {
      if (verificationResult.value.verified) {
        const passwords = {
          current: verificationResult.value.currentPassword,
          new: verificationResult.value.newPassword
        };
        postChangePassword(passwords);
      }
      isSubmitting.value = false;
    });

    const status = ref(null);

    async function postChangePassword(passwords) {
      status.value = "";

      const currentPassword = passwords.current;
      const newPassword = passwords.new;

      if (currentPassword !== newPassword) {
        const payload = {
          credentials: {
            email: email.value,
            password: currentPassword
          },
          newPassword: newPassword
        };

        const result = await store.dispatch("auth/changePassword", payload);

        if (result) {
          context.emit("close");
        } else {
          status.value = "Change failed.";
        }
      }
    }

    function toggleCurrentPasswordVisibility() {
      if (currentPasswordInputType.value === "password") {
        currentPasswordInputType.value = "text";
        currentPasswordIcon.value = "eye";
      } else if (currentPasswordInputType.value === "text") {
        currentPasswordInputType.value = "password";
        currentPasswordIcon.value = "eye-slash";
      }
    }

    function toggleNewPasswordVisibility() {
      if (newPasswordInputType.value === "password") {
        newPasswordInputType.value = "text";
        newPasswordIcon.value = "eye";
      } else if (newPasswordInputType.value === "text") {
        newPasswordInputType.value = "password";
        newPasswordIcon.value = "eye-slash";
      }
    }

    return {
      isLoading,
      email,
      currentPassword,
      currentPasswordIcon,
      toggleCurrentPasswordVisibility,
      currentPasswordInputType,
      newPassword,
      newPasswordIcon,
      newPasswordInputType,
      toggleNewPasswordVisibility,
      status,
      isSubmitting,
      getVerification,
      pressSave
    };
  }
};
</script>

<style scoped lang="scss">
.headline {
  color: var(--overwatch-neutral-100);
  @include overwatch-title-med;
  line-height: 30px;
}

.settings-line {
  height: 20px;
  width: 39px;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-small;
  line-height: 21px;
}

.disabledDiv {
  pointer-events: none;
  opacity: 0.4;
}

.footer {
  color: var(--overwatch-neutral-100);
  @include overwatch-body-xsmall;
  line-height: 18px;
  margin-bottom: 20px;
}

.saveSettingsButton {
  background: var(--overwatch-primary);
  color: var(--overwatch-button-text) !important;
  font-size: 14px;
}

.reset {
  height: 40px;
  width: 45px;
  color: var(--overwatch-neutral-100);
  @include overwatch-body-small;
  line-height: 40px;
  text-align: right;
  vertical-align: middle;
  margin-right: 30px;
  cursor: pointer;
}

.rectangle {
  box-sizing: border-box;
  height: 40px;
  width: 460px;
  border: 1px solid var(--overwatch-neutral-300);
  background-color: var(--overwatch-neutral-500);
  margin-top: 2px;
}

.user {
  @include overwatch-body-med;
}

.inputEmail {
  width: 100%;
}
</style>

<template>
  <div class="warningToast">
    <RocIcon :color="iconColor" icon="unknown" />
    <div>{{ message }}</div>
    <div class="exit" @click="handleClickClose">
      <RocIcon color="black" size="sm" icon="exit" />
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, watch } from "vue";
import RocIcon from "@/components/ui/RocIcon.vue";

const props = defineProps({
  type: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  },
  duration: {
    type: Number,
    default: 5000
  },
  index: {
    type: Number,
    default: 1
  },
  ID: {
    type: String,
    required: false
  },
  bgColor: {
    type: String,
    default: "var(--overwatch-primary)"
  },
  iconColor: {
    type: String,
    default: "red"
  },
});
const emits = defineEmits(["close"]);

const bgColor = ref(props.bgColor);
const offset = ref(props.index * 75 + "px");
let timer = undefined;
startTimer();


function startTimer(){
  timer = setTimeout(() => emits("close", props.ID), props.duration);
}

watch(() => props.duration, (nv) => {
  clearTimeout(timer);
  timer = undefined;
  startTimer();
});


function handleClickClose() {
  emits("close", props.ID);
}
</script>

<style scoped lang="scss">
.warningToast {
  z-index: 97;
  display: flex;
  gap: var(--spacing-xs);
  align-items: center;
  justify-content: start;
  padding-left: 15px;
  position: absolute;
  left: 40%;
  bottom: calc(var(--spacing-xxl) + v-bind(offset));
  height: 50px;
  width: 350px;
  border: solid 1px black;
  background-color: v-bind(bgColor);
  border-radius: 5px;
}
.exit {
  position: inherit;
  top: 5px;
  right: 2%;
}
</style>
